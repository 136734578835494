import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import theme from '../assets/theme'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

export interface BoxedContentProps {
  title?: string
  children?: ReactNode
}

const StyledBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  padding: ${theme.spacing(12)} ${theme.spacing(16)};
  flex-direction: column;
  gap: ${theme.spacing(16)};
  border-radius: ${theme.spacing(4)};
  border: 1px solid ${theme.palette.common.buttonColorOnDisabled};
  background: ${theme.palette.common.white};
  box-shadow: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.palette.common.shadow};

  ${theme.breakpoints.down(400)} {
    padding-top: ${theme.spacing(8)};
  }
`

function BoxedContent({ title, children }: BoxedContentProps) {
  return (
    <StyledBox>
      {title && (
        <Typography
          variant="h3"
          data-cy="boxed-content-title"
          marginBottom={{ xs: theme.spacing(2), sm: theme.spacing(4) }}
        >
          {title}
        </Typography>
      )}
      {children}
    </StyledBox>
  )
}

export default BoxedContent
