const stringToBooleanMap: { [key: string]: boolean } = {
  true: true,
  false: false,
  '1': true,
  '0': false,
  yes: true,
  no: false
}

export const convertToBoolean = (value: string) => stringToBooleanMap[value]
