import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import { default as themeGlobal } from '../theme'

export const maxWidthLargeDevices = '476px'
export const minWidthSmallDevices = '343px'

export const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'smGapSize' && prop !== 'xsGapSize'
})<{ smGapSize?: string; xsGapSize?: string }>(
  ({ theme, smGapSize, xsGapSize }) => `
  display: flex;
  flex-direction: column;
  max-width: ${maxWidthLargeDevices};
  row-gap: ${smGapSize ?? themeGlobal.spacing(16)};
  ${themeGlobal.breakpoints.down('sm')} {
    row-gap: ${xsGapSize ?? themeGlobal.spacing(16)};
    min-width: ${minWidthSmallDevices};
  }`
)

export const ContentGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: ${themeGlobal.spacing(12)};
  ${themeGlobal.breakpoints.down(400)} {
    row-gap: ${themeGlobal.spacing(8)};
  }
`
