export const maxLength75 = {
  value: 75,
  message: 'Maximum 75 characters'
}

export const maxLength150 = {
  value: 150,
  message: 'Maximum 150 characters'
}

export const maxLength250 = {
  value: 250,
  message: 'Maximum 250 characters'
}
