export enum RiskDecisionStatus {
  None = 'none',
  InReview = 'inreview',
  OnHold = 'onhold',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected'
}

export const InvalidCompanyStatus = [
  RiskDecisionStatus.InReview,
  RiskDecisionStatus.Rejected,
  RiskDecisionStatus.OnHold
]
