import { OnboardingJourney } from '../types/OnboardingJourney'
import { OnboardingJourneyStatus } from '../types/enums/OnboardingJourneyStatus'
import { apiClient } from '../api/apiClient'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { validate } from 'uuid'

export const fetchOnboardingJourneyKey = 'fetch-onboarding-journey'

const validateOnboardingJourneyParams = (merchantId?: string, merchantCompanyIdentifier?: string): boolean => {
  if (merchantId !== undefined && merchantCompanyIdentifier !== undefined && validate(merchantId)) {
    return true
  }
  return false
}
export const fetchOnboardingJourneyDetails = async (
  merchantId: string,
  merchantCompanyIdentifier: string
): Promise<OnboardingJourney> => {
  const response = await apiClient.get<OnboardingJourney>(`/${merchantId}/${merchantCompanyIdentifier}`)
  return response.data
}

let retryCount = 0
let retryOnError = false
export const useOnboardingJourneyInfo = (companyId?: string, merchantCompanyIdentifier?: string) => {
  const [localLoading, setLocalLoading] = useState(true)

  const { data, isLoading, error, refetch } = useQuery<OnboardingJourney, Error>(
    [fetchOnboardingJourneyKey, companyId, merchantCompanyIdentifier],
    () => fetchOnboardingJourneyDetails(companyId!, merchantCompanyIdentifier!),
    {
      retry: retryOnError,
      refetchOnWindowFocus: false,
      enabled: validateOnboardingJourneyParams(companyId, merchantCompanyIdentifier),
      onSettled: (fetchedData) => {
        if (
          fetchedData &&
          fetchedData.status === OnboardingJourneyStatus.New &&
          fetchedData.company === null &&
          !fetchedData.companyDetailsToBeProvidedByOnboardingJourney &&
          retryCount < 3
        ) {
          setTimeout(async () => {
            setLocalLoading(true)
            retryCount++
            await refetch()
          }, 1000)
        } else {
          setLocalLoading(false)
        }
      },
      onError: () => {
        retryOnError = true
      }
    }
  )

  const dataLoading = isLoading ? isLoading : localLoading

  return { data, dataLoading, error }
}
