import { MESSAGES, createVeriffFrame } from '@veriff/incontext-sdk'
import React, { useEffect, useRef } from 'react'
import { useCheckKycStatus } from '../../custom-hooks/useCheckKycStatus'
import PageLoading from '../../components/loading/PageLoading'

type DirectorKycSessionProps = {
  onboardingJourneyId: string
  abandonedUrl: string
  sessionUrl: string
}
export const veriffFrameId = 'load-veriff-iframe'

const DirectorKycSession = (props: DirectorKycSessionProps) => {
  const myElementRef = useRef<any>(null)

  const abandonedUrl = props.abandonedUrl
  const sessionUrl = props.sessionUrl

  const { startPolling, isPolling } = useCheckKycStatus({
    onboardingJourneyId: props.onboardingJourneyId
  })

  useEffect(() => {
    let shouldCleanup = false

    if (myElementRef.current?.id !== veriffFrameId) {
      myElementRef.current = createVeriffFrame({
        url: sessionUrl,
        onEvent: function (msg) {
          switch (msg) {
            case MESSAGES.CANCELED:
              window.location.replace(abandonedUrl)
              shouldCleanup = true
              break
            case MESSAGES.FINISHED:
              startPolling(true)
              shouldCleanup = true
              break
          }
        }
      })
      myElementRef.current.id = veriffFrameId
    }

    return () => {
      if (myElementRef.current?.id === veriffFrameId && shouldCleanup) {
        myElementRef.current.close()
      }
    }
  }, [sessionUrl, abandonedUrl])

  if (isPolling) {
    return <PageLoading title="Verifying" body="Please don't close the window."></PageLoading>
  } else {
    return <div ref={myElementRef}></div>
  }
}

export default DirectorKycSession
