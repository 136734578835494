import ErrorPageContainer from './ErrorPageContainer'
import React from 'react'
import Box from '@mui/material/Box'

const CheckShoulBeReRequested = () => {
  return (
    <ErrorPageContainer title="The link is no longer valid">
      <Box data-cy="message">Something's gone wrong. Please start the verification process again.</Box>
    </ErrorPageContainer>
  )
}

export default CheckShoulBeReRequested
