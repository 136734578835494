import { useQuery } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { EligibilityQuestionsResponse } from '../types/EligibilityQuestionsResponse'

export const fetchEligibilityQuestionsDataKey = 'fetch-eligibility-questions-data'

export const fetchEligibilityQuestions = async (): Promise<EligibilityQuestionsResponse> => {
  const response = await apiClient.get<EligibilityQuestionsResponse>('/manualeligibilityquestions')
  return response.data
}

export const useFetchEligibilityQuestions = () => {
  const { data, isLoading } = useQuery<EligibilityQuestionsResponse, Error>(
    [fetchEligibilityQuestionsDataKey],
    () => fetchEligibilityQuestions(),
    { retry, refetchOnWindowFocus: false }
  )

  return { data, isLoading }
}
