import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import { ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

type PageLoadingProps = {
  title: string | ReactNode
  body?: string
  returnLink?: string
  returnLinkName?: string
}
const PageLoading = (props: PageLoadingProps) => {
  const theme = useTheme()

  return (
    <Backdrop
      sx={{
        background: theme.palette.common.white
      }}
      open={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '75vh',
          gap: '20px'
        }}
      >
        <Typography marginBottom="25px" variant="PageLoadingHeader" textAlign="center" id="PageLoadingHeader">
          {props.title}
        </Typography>
        <Box sx={{ position: 'relative' }} id="spinner">
          <CircularProgress
            variant="determinate"
            sx={{ color: theme.palette.common.backgroundGrey100 }}
            value={100}
            size={64}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={64}
            color="primary"
            sx={{ position: 'absolute', left: 0, animationDuration: '600ms' }}
          />
        </Box>
        <Typography margin="25px" variant="body2" data-cy="loader-body">
          {props.body}
        </Typography>
        {props.returnLink && (
          <Link
            component="button"
            variant="caption"
            id="return-to-merchant-btn"
            onClick={() => {
              window.location.replace(props.returnLink!)
            }}
          >
            Return to {props.returnLinkName}
          </Link>
        )}
      </div>
    </Backdrop>
  )
}

export default PageLoading
