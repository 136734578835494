import Button from '@mui/material/Button'
import ErrorPageContainer, { StyledErrorPageButtonContainer } from './ErrorPageContainer'
import Box from '@mui/material/Box'

export interface CheckIsFailedProps {
  returnUrl: string
  merchantName: string
}

const CheckIsFailed = (props: CheckIsFailedProps) => {
  const handleReturnClick = () => {
    window.location.href = props.returnUrl
  }

  return (
    <ErrorPageContainer title="Verification failed">
      <Box data-cy="message">Sorry, but you did not successfully pass the verification check.</Box>
      <br />
      <Box>
        Unfortunately, we can't process your payment through Kriya at this time. Please go back to {props.merchantName}{' '}
        and select a different payment method to finalise your order.
      </Box>
      {props.returnUrl && (
        <StyledErrorPageButtonContainer>
          <Button variant="contained" id="return-button" onClick={handleReturnClick}>
            Return to {props.merchantName}
          </Button>
        </StyledErrorPageButtonContainer>
      )}
    </ErrorPageContainer>
  )
}

export default CheckIsFailed
