import React, { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Address } from '../../types/Address'
import TextInput from '../TextInput'
import ReadOnlyField from '../ReadOnlyField'
import AddressSearch from './AddressSearch'
import Button from '@mui/material/Button'
import { maxLength150, maxLength250 } from '../../utils/validationConstants'

type AddressFormProps = {
  form: UseFormReturn<Address>
}

const addressLine1Error = 'Please enter first line of address'
const cityError = 'Please enter city'
const postCodeError = 'Please enter post code'

const AddressForm = ({ form }: AddressFormProps) => {
  const {
    register,
    getValues,
    resetField,
    trigger,
    formState: { errors }
  } = form

  const [isManual, setIsManual] = useState<boolean>(false)
  const onUseAddressFinderClick = async () => {
    resetField('addressLine1')
    resetField('addressLine2')
    resetField('city')
    resetField('region')
    resetField('postCode')
    await trigger(['addressLine1', 'city', 'postCode'])
    setIsManual(false)
  }

  const onUseManualInputClick = () => {
    setIsManual(true)
  }

  if (isManual) {
    return (
      <>
        <Button variant="component" onClick={onUseAddressFinderClick} data-cy="use-address-finder">
          Use address finder
        </Button>
        <TextInput
          label="Address line 1"
          name="addressLine1"
          register={register}
          error={errors.addressLine1}
          validation={{
            required: addressLine1Error,
            validate: (value: string) => value.trim().length > 0 || addressLine1Error,
            maxLength: maxLength250
          }}
        />
        <TextInput
          label="Address line 2"
          name="addressLine2"
          register={register}
          error={errors.addressLine2}
          optional={true}
          validation={{
            maxLength: maxLength250
          }}
        />
        <TextInput
          label="City"
          name="city"
          register={register}
          error={errors.city}
          validation={{
            required: cityError,
            validate: (value: string) => value.trim().length > 0 || cityError,
            maxLength: maxLength150
          }}
        />
        <TextInput
          label="Region, state or county"
          name="region"
          register={register}
          error={errors.region}
          optional={true}
          validation={{
            maxLength: maxLength150
          }}
        />
        <ReadOnlyField label="Country" name="country" getValues={getValues} />
        <TextInput
          label="Postcode"
          name="postCode"
          register={register}
          error={errors.postCode}
          validation={{ required: postCodeError, maxLength: maxLength150 }}
        />
      </>
    )
  }

  return <AddressSearch form={form} onUseManualInputClick={onUseManualInputClick}></AddressSearch>
}

export default AddressForm
