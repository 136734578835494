interface IUnleashApiKeyMapping {
  [Key: string]: string
}

export const stagingUnleashApiKey = 'default:development.2c08be16edfabc745fcc849de2e68f440d9a9cf32f180b74643e4530'
export const productionUnleashApiKey = 'default:production.8c5861582ea0d55ab1a428b49f6c0f9bee02e65510a264b212baf342'

const keyMapping: IUnleashApiKeyMapping = {
  'onboarding.kriya.co': productionUnleashApiKey,
  'onboarding.kriya.dev': stagingUnleashApiKey,
  'localhost:3000': stagingUnleashApiKey
}

const unleashApiKey = (currentUrl: string): string => {
  const host = new URL(currentUrl).host
  return keyMapping[host]
}

export default unleashApiKey
