import { useMutation, useQueryClient } from 'react-query'

import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate, useParams } from 'react-router-dom'

export const initiateSoleTraderOnboardingJourneyDetails = async (
  merchantId: string,
  onboardingJourneyIdentifier: string,
  directorEmail: string
): Promise<number> => {
  const response = await apiClient.post<number>('initiateSoleTraderFlow', {
    merchantId,
    onboardingJourneyIdentifier,
    directorEmail
  })
  return response.status
}

export const useInitiateSoleTraderOnboardingJourney = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (directorEmail: string): Promise<number> => {
      return await initiateSoleTraderOnboardingJourneyDetails(merchantId!, merchantCompanyIdentifier!, directorEmail)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
