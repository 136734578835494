import theme from '../../../assets/theme'

import Box from '@mui/material/Box'
import { RiskDecision } from '../../../types/RiskDecision'
import Typography from '@mui/material/Typography'
import { Company } from '../../../types/Company'
import LimitsBox from '../../../components/LimitsBox'
import { OnboardingJourneyCheck } from '../../../types/OnboardingJourneyCheck'
import { DirectorContactForm } from './DirectorStep'
import { Controller, UseFormReturn } from 'react-hook-form'
import { CheckType } from '../../../types/enums/CheckType'
import FormControl from '@mui/material/FormControl'
import SignedTermsCheck from '../../../components/termsAndConditionsCheck/SignedTermsCheck'

const DirectorStepContent = ({
  riskDecision,
  company,
  checks,
  form
}: {
  riskDecision: RiskDecision
  company: Company
  checks: OnboardingJourneyCheck[] | undefined
  form: UseFormReturn<DirectorContactForm, any, undefined>
}) => {
  const { control, setValue } = form
  const hasCheckOfType = (checkType: CheckType) => (checks?.filter((r) => r.type === checkType).length || 0) > 0
  return (
    <Box
      sx={{
        rowGap: theme.spacing(16),
        display: 'grid',
        marginTop: {
          xs: theme.spacing(24),
          sm: theme.spacing(32)
        }
      }}
    >
      <LimitsBox
        companyName={company.name}
        totalSpendingLimit={riskDecision.totalSpendingLimit}
        availableSpendingLimit={riskDecision.availableSpendingLimit}
        currency={riskDecision.currency}
        eligibilitySubject={'for Kriya PayLater'}
      />
      <Box
        sx={{
          mt: theme.spacing(16)
        }}
      >
        <Typography variant="body2" data-cy="content">
          Complete a quick identity verification to activate your spending limit. We've sent an email to the previously
          provided email address to start the verification process. You can also begin the verification right now by
          clicking the button below.
          <Box
            sx={{
              mt: theme.spacing(16)
            }}
          >
            <Typography variant="body2semibold" data-cy="recommendation">
              Please ensure it is the organisation owner who performs the checks.
            </Typography>
          </Box>
        </Typography>
      </Box>

      {hasCheckOfType(CheckType.SignedTerms) && (
        <Controller
          control={control}
          name="termsAndConditions"
          rules={{
            required: true
          }}
          render={({ field }) => (
            <Box>
              <FormControl fullWidth>
                <SignedTermsCheck
                  field={field}
                  setValue={(value) => setValue('termsAndConditions', value, { shouldValidate: true })}
                />
              </FormControl>
            </Box>
          )}
        />
      )}
    </Box>
  )
}

export default DirectorStepContent
