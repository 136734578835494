import { maxWidthLargeDevices, minWidthSmallDevices } from '../../../assets/styles/ContentStyles'

import ActionButton from '../../../components/ActionButton'
import Box from '@mui/material/Box'
import DirectorStepContent from './DirectorStepContent'
import { OnboardingJourney } from '../../../types/OnboardingJourney'
import theme from '../../../assets/theme'
import Logo from '../../../components/Logo'
import ReturnToMarketplaceDialog from '../../../components/returnToMarketplace'
import { StyledButtonsContainer } from '../../../components/layout/Container'
import { OnboardingJourneySource } from '../../../types/enums/OnboardingJourneySource'
import { useInitiateKyc } from '../../../custom-hooks/useInitiateKyc'
import { useUpdateOnboardingJourneyStatus } from '../../../custom-hooks/useUpdateOnboardingJourneyStatus'
import { OnboardingJourneyStatus } from '../../../types/enums/OnboardingJourneyStatus'
import { useForm } from 'react-hook-form'

const captionBoxStyling = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px'
}
export interface DirectorContactForm {
  termsAndConditions?: boolean
}

const DirectorStep = (data: OnboardingJourney) => {
  const { company, riskDecision, status: journeyStatus } = data

  const { mutate: initiateKycMutation, isLoading: isInitiateKycLoading } = useInitiateKyc({
    onboardingJourneyId: data.id
  })

  const { mutate: updateJourneyStatus, isLoading: isUpdateJourneyStatusLoading } = useUpdateOnboardingJourneyStatus({
    onboardingJourneyId: data.id
  })

  const isMutationLoading = isInitiateKycLoading || isUpdateJourneyStatusLoading

  const form = useForm<DirectorContactForm>({
    mode: 'onChange'
  })

  const onSubmit = () => {
    if (!isMutationLoading) {
      if (journeyStatus === OnboardingJourneyStatus.DirectorKycCheckIsRequested) {
        updateJourneyStatus({})
      } else {
        initiateKycMutation()
      }
    }
  }

  return (
    <Box
      sx={{
        maxWidth: { sm: maxWidthLargeDevices },
        minWidth: { xs: minWidthSmallDevices }
      }}
    >
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <form id="director-form" style={{ rowGap: captionBoxStyling.rowGap, display: 'grid' }}>
        <DirectorStepContent riskDecision={riskDecision} company={company} checks={data.checks} form={form} />
        <Box sx={captionBoxStyling}>
          <StyledButtonsContainer
            sx={{
              marginTop: 0
            }}
          >
            <ActionButton
              id="continue-button"
              disabled={!form.formState.isValid || isMutationLoading}
              loading={isMutationLoading}
              onClick={onSubmit}
            >
              Start verification
            </ActionButton>
            {data.source !== OnboardingJourneySource.MerchantPortal && data.abandonedUrl && (
              <ReturnToMarketplaceDialog
                marketplace={data.merchant.name}
                disabled={isMutationLoading}
                redirectUrl={data.abandonedUrl}
              ></ReturnToMarketplaceDialog>
            )}
          </StyledButtonsContainer>
        </Box>
      </form>
    </Box>
  )
}

export default DirectorStep
