import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import React from 'react'
import EligibilityQuestion from './EligibilityQuestion'
import styled from '@emotion/styled'
import theme from '../assets/theme'

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  questions: {
    id: Path<T>
    description: string
  }[]
}

const StyledList = styled.ol`
  & > li {
    margin-bottom: ${theme.spacing(16)};

    div {
      margin-left: ${theme.spacing(-9)};
    }
  }

  padding-inline-start: ${theme.spacing(9)};
`

const EligibilityQuestionWrapper: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  questions,
  register
}) => {
  return (
    <StyledList>
      {questions.map((q) => (
        <EligibilityQuestion key={q.id} name={q.id} title={q.description} register={register} />
      ))}
    </StyledList>
  )
}

export default EligibilityQuestionWrapper
