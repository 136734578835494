import theme from '../../../assets/theme'

import Box from '@mui/material/Box'
import { RiskDecision } from '../../../types/RiskDecision'
import Typography from '@mui/material/Typography'
import { Company } from '../../../types/Company'
import LimitsBox from '../../../components/LimitsBox'

const DirectorStepContent = ({ riskDecision, company }: { riskDecision: RiskDecision; company: Company }) => {
  return (
    <Box
      sx={{
        marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
      }}
    >
      <LimitsBox
        companyName={company.name}
        totalSpendingLimit={riskDecision.totalSpendingLimit}
        availableSpendingLimit={riskDecision.availableSpendingLimit}
        currency={riskDecision.currency}
        eligibilitySubject={'for Kriya PayLater'}
      />
      <Box
        sx={{
          mt: theme.spacing(12),
          mb: theme.spacing(16)
        }}
      >
        <Typography variant="body2" data-cy="content">
          There's only one more step left to activate the spending limit. Please select a director and provide their
          email. We'll send a link for conducting director identity check. The spending limit will be unlocked once the
          check is completed successfully.{' '}
          <Typography variant="body2semibold" data-cy="recommendation">
            If you are one of the organisation's directors, please select yourself to continue.
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

export default DirectorStepContent
