import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import React from 'react'
import Spinner from './loading/Spinner'

interface ButtonProps {
  id?: string
  name?: string
  loading?: boolean
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  style?: React.CSSProperties
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children: React.ReactNode
}

const ActionButton: React.FC<ButtonProps> = ({
  id,
  name,
  type,
  style,
  loading,
  disabled,
  onClick,
  children
}: ButtonProps) => {
  return (
    <Button
      variant="contained"
      id={id}
      name={name}
      type={type || 'submit'}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {children}
      {loading && (
        <>
          {loading}
          <Box>
            <Spinner></Spinner>
          </Box>
        </>
      )}
    </Button>
  )
}
export default ActionButton
