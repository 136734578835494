import theme from '../../assets/theme'

const captionBoxStyling = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
  p: {
    sm: theme.spacing(10, 0, 0),
    xs: theme.spacing(6, 0, 0)
  }
}

export default captionBoxStyling
