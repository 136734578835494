export enum OnboardingJourneyStatus {
  New = 'New',
  CompanyCheckIsRequested = 'CompanyCheckIsRequested',
  CompanyCheckHasCompletedWithIneligible = 'CompanyCheckHasCompletedWithIneligible',
  CompanyCheckHasCompletedWithEligible = 'CompanyCheckHasCompletedWithEligible',
  CompanyCheckHasCompletedWithManualCheck = 'CompanyCheckHasCompletedWithManualCheck',
  DirectorKycCheckIsRequested = 'DirectorKycCheckIsRequested',
  DirectorKycCheckIsPending = 'DirectorKycCheckIsPending',
  DirectorKycCheckDocumentUploaded = 'DirectorKycCheckDocumentUploaded',
  DirectorKycCheckIsFailed = 'DirectorKycCheckIsFailed',
  DirectorKycCheckShouldBeReRequested = 'DirectorKycCheckShouldBeReRequested',
  OnboardingJourneyIsSuccessfullyCompleted = 'OnboardingJourneyIsSuccessfullyCompleted'
}

export const CustomerDetailsStatuses = [OnboardingJourneyStatus.New, OnboardingJourneyStatus.CompanyCheckIsRequested]
