import { Content, ContentGroup } from '../../assets/styles/ContentStyles'

import ActionButton from '../../components/ActionButton'
import Logo from '../../components/Logo'
import React from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import { useUpdateOnboardingJourneyStatus } from '../../custom-hooks/useUpdateOnboardingJourneyStatus'
import { Controller, useForm } from 'react-hook-form'
import { defaultCountryCode } from '../../consts'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ErrorText from '../error/FormErrors'
import { StyledButtonsContainer } from '../../components/layout/Container'
import { CheckType } from '../../types/enums/CheckType'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import SignedTermsCheck from '../../components/termsAndConditionsCheck/SignedTermsCheck'
import Stack from '@mui/material/Stack'

export interface DirectorContactForm {
  phoneNumber?: string
  termsAndConditions?: boolean
}

const DirectorKycContent: React.FC<OnboardingJourney> = (data: OnboardingJourney) => {
  const { mutate: updateOnboardingJourneyStatusMutation, isLoading: isMutationLoading } =
    useUpdateOnboardingJourneyStatus({
      onboardingJourneyId: data.id
    })

  const form = useForm<DirectorContactForm>({
    mode: 'onChange'
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = form

  const onSubmit = (formValues: DirectorContactForm) => {
    updateOnboardingJourneyStatusMutation({
      phoneNumber: formValues.phoneNumber
    })
  }

  const hasCheckOfType = (checkType: CheckType) => (data.checks?.filter((r) => r.type === checkType).length || 0) > 0

  return (
    <Content>
      {data.merchant?.logoUri ? <Logo logoUri={data.merchant.logoUri} /> : <br />}
      <Typography variant="h2" data-cy="title" marginTop={theme.spacing(16)}>
        Welcome to Kriya
      </Typography>

      <ContentGroup>
        <Typography variant="body2" data-cy="content">
          Hi {data.director.fullName},
        </Typography>

        <Typography variant="body2" data-cy="congratulatory-content">
          We'll help you with the ID verification process. Please have your ID document handy. Once verified, you'll
          receive a confirmation email and can begin using Kriya PayLater for your purchases.
        </Typography>
      </ContentGroup>

      <form
        onSubmit={handleSubmit(onSubmit)}
        id="director-kyc-form"
        style={{ rowGap: theme.spacing(24), display: 'grid' }}
      >
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (value) => !value || matchIsValidTel(value, { onlyCountries: [defaultCountryCode] })
          }}
          render={({ field, fieldState }) => (
            <Box>
              <FormControl fullWidth>
                <FormLabel htmlFor="phoneNumber">Enter your mobile</FormLabel>
                <MuiTelInput
                  {...field}
                  sx={{ width: '100%' }}
                  data-cy="phoneNumber"
                  defaultCountry={defaultCountryCode}
                  onlyCountries={[defaultCountryCode]}
                  disableDropdown={true}
                  error={fieldState.invalid}
                />
              </FormControl>
              {fieldState.invalid && <ErrorText id={field.name}>Please enter a valid mobile phone number</ErrorText>}
            </Box>
          )}
          name="phoneNumber"
        />

        {hasCheckOfType(CheckType.SignedTerms) && (
          <Controller
            control={control}
            name="termsAndConditions"
            rules={{
              required: true
            }}
            render={({ field }) => (
              <Box>
                <FormControl fullWidth>
                  <SignedTermsCheck
                    field={field}
                    setValue={(value) => setValue('termsAndConditions', value, { shouldValidate: true })}
                  />
                </FormControl>
              </Box>
            )}
          />
        )}

        <StyledButtonsContainer style={{ marginTop: 0 }}>
          <Stack>
            {data.director.fullName ? (
              <ActionButton
                id="start-button"
                loading={isMutationLoading}
                disabled={!form.formState.isValid || isMutationLoading}
              >
                Start
              </ActionButton>
            ) : null}
            <ContentGroup style={{ marginTop: theme.spacing(8) }}>
              <Typography variant="subtitle1" data-cy="note-content">
                Please note that this verification page is for {data.director.fullName}’s use only.
              </Typography>
            </ContentGroup>
          </Stack>
        </StyledButtonsContainer>
      </form>
    </Content>
  )
}

export default DirectorKycContent
