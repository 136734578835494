import { RiskDecisionStatus } from '../../types/enums/RiskDecisionStatus'

export const OnboardingJourneyRedirection = (status: RiskDecisionStatus) => {
  const riskStatus = status.toLowerCase()

  switch (riskStatus) {
    case RiskDecisionStatus.Submitted:
    case RiskDecisionStatus.InReview:
    case RiskDecisionStatus.OnHold:
      return 'in-review'
    case RiskDecisionStatus.Rejected:
      return 'rejected'
    default:
      return undefined
  }
}
