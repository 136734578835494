export const TermsAndConditions = [
  {
    title: 'INTRODUCTION',
    text: 'This PayLater Agreement contains the terms that determine the rights and obligations between you and us under which an PayLater Payment Terms Application can be made to obtain PayLater Payment Terms. By agreeing with the "PayLater Terms and Conditions", you agree that you have read, understand, and accept the terms and conditions contained in this PayLater Agreement and agree to be bound as a Customer.'
  },
  {
    title: 'PAYLATER PAYMENT TERMS APPLICATION',
    content: [
      {
        text: 'Where you agree the terms of this PayLater Agreement and are accepted by Kriya, Kriya will, subject to any credit or risk approvals, use reasonable endeavours to provide PayLater Payment Terms. You must in respect of each set of PayLater Payment Terms, first provide to Kriya any information Kriya requires. Please note that PayLater Payment Terms are only available with respect to the purchases that you make which relate solely for the purposes of the business carried on by you.'
      },
      {
        text: 'Once submitted, an application for PayLater Payment Terms cannot be withdrawn.'
      },
      {
        text: 'Once submitted, an application for PayLater Payment Terms can be amended only with the consent of Kriya.'
      },
      {
        text: 'By submitting an PayLater Payment Terms Application, and each day on which you are a Customer member of the PayLater Platform, you represent and warrant that:',
        content: [
          {
            text: 'by agreeing to be bound by the PayLater Agreement you have not and will not violate any law, regulation, judicial order, security interest or other contract applicable to you; and'
          },
          {
            text: 'all incorporation and/or identification documents, authorisations, banking details, financial, trading and other information provided by or on behalf of you to us is true and accurate in all material respects and does not omit any material fact.'
          }
        ]
      }
    ]
  },
  {
    title: 'YOUR PROFILE',
    content: [
      {
        text: 'Your Profile on the PayLater Platform contains information submitted by you and various other matters as added by Kriya from time to time. Your Profile and accounts are only viewable by Kriya.'
      },
      {
        text: 'By applying for PayLater Payment Terms, you confirm to Kriya that the information on you supplied to Kriya, all or some part of which is contained in your Profile, is complete, accurate and up to date.'
      }
    ]
  },
  {
    title: 'REVIEW OF APPLICATION',
    content: [
      {
        text: 'You may apply for PayLater Payment Terms through submission of an PayLater Payment Terms Application.'
      },
      {
        text: 'After Kriya has received your PayLater Payment Terms Application, Kriya will notify you whether the application is accepted or not. We may, in our sole discretion, approve or reject any application and attach conditions to our acceptance of such application. Kriya does not have to give reasons for its decision.'
      },
      {
        text: 'Kriya may terminate an PayLater Payment Terms Application if it has any concern about you or the PayLater Payment Terms Application process.'
      }
    ]
  },
  {
    title: 'PAYLATER REQUIREMENTS',
    content: [
      {
        text: 'In relation to each PayLater Payment Terms Application which is approved, you will be provided with the PayLater Payment Terms Summary which sets out the amount payable in respect of each PayLater Payment Terms, the Payment Schedule and other key terms. You agree to enter into each set of PayLater Payment Terms in accordance with the PayLater Agreement.'
      },
      {
        text: 'You must comply with the requirements of the PayLater Payment Terms Summary and the other terms of the PayLater Agreement.'
      },
      {
        text: 'You must sign/set up any Payment Method required by us which will typically be direct debit or bank transfer.'
      },
      {
        text: 'In respect of each set of PayLater Payment Terms, Kriya’s obligations thereunder (including taking responsibility for paying the Purchase Amount (less any fees due by the Merchant and/or Stripe (as applicable)) to the Merchant (or if you are a Stripe Customer, to Stripe), is conditional upon Kriya receiving and/or being satisfied with:',
        content: [
          {
            text: 'you agreeing to the PayLater Agreement and it being binding on you;'
          },
          {
            text: 'the documents/information required by Kriya to comply with its customer identity requirements and all know-your-customer checks having been completed to Kriya’s satisfaction;'
          },
          {
            text: 'no Default having occurred and/or is continuing;'
          },
          {
            text: 'the setting up the Payment Method; and'
          },
          {
            text: 'any other information or documents Kriya reasonably requires, or as otherwise agreed between Kriya and the Merchant.'
          }
        ]
      }
    ]
  },
  {
    title: 'COMPLETION',
    content: [
      {
        text: 'If the PayLater Payment Terms Application is successful, you will be provided with the PayLater Payment Terms Summary for the PayLater Payment Terms (including the amount payable by you and the Payment Schedule). The approval of your PayLater Payment Terms Application and subsequent receipt by you of PayLater Payment Terms shall constitute a Credit Agreement. '
      },
      {
        text: 'Subject to the receipt and satisfaction of the requirements in Clause 5.4 and you having executed and/or delivered any relevant documents, Kriya shall become responsible for making payment of the relevant Purchase Amount to the Merchant, or if you are a Stripe Customer, to Stripe (less any fees that are payable by the Merchant and/or Stripe (as applicable) to Kriya). '
      },
      {
        text: 'Upon Kriya becoming responsible for making payment of the relevant Purchase Amount to the Merchant (or if you are a Stripe Customer, to Stripe) (less any fees that are payable by the Merchant and/or Stripe (as applicable) to Kriya), Kriya will then service the PayLater Payment Terms and administer the collection of payments from you and other relevant matters. Thereafter, your liability to repay the Purchase Amount and (if applicable) any late payment fees due on the PayLater Payment Terms is owed to Kriya.'
      },
      {
        text: 'No amount repaid may be redrawn.'
      },
      {
        text: 'In the event that the requirements in relation to Kriya taking responsibility for making payment of the relevant Purchase Amount to the Merchant are not satisfied by the latest date that such Purchase Amount is to be paid, the PayLater Payment Terms applicable to such Purchase Amount shall be withdrawn and cease to have effect.'
      }
    ]
  },
  {
    title: 'REPAYMENT',
    content: [
      {
        text: 'You will repay the Purchase Amount to Kriya, in accordance with the Payment Schedule, by making the Payments.'
      },
      {
        text: 'Payments are to be made on each Payment Date as shown in the Payment Schedule. Payments must be made by the Payment Method from your bank account. Where the Payment amount is a number that is not a whole number of pence, it will be rounded to the nearest whole penny.'
      }
    ]
  },
  {
    title: 'LATE PAYMENT FEES',
    content: [
      {
        text: 'If you do not make any of the Payments on their Payment Dates, a late payment fee will be charged to you equal to one point five per cent (1.5%) (or such higher amount as is notified to you by Kriya) of the then outstanding balance.'
      },
      {
        text: 'Additionally, you will be responsible for paying in full all expenses (including reasonable legal fees) incurred by us at any time (and any additional administrative time incurred by us charged at such rate as we may reasonably determine) directly or indirectly as a result of failure to make any of the Payments on their Payment Date.'
      },
      {
        text: 'In the event that you make late payment of a Payment within thirty (30) days following the relevant Payment Date, Kriya may at its discretion waive any such late payment fee and/or expenses incurred in respect of the late payment.'
      }
    ]
  },
  {
    title: 'EARLY REPAYMENT',
    text: 'You may repay the whole or part of the balance of the Purchase Amount outstanding prior to a Payment Date, without any penalty.'
  },
  {
    title: 'RETURN OF GOODS',
    content: [
      {
        text: 'In the event you wish to and are entitled to return Goods that are subject to PayLater Payment Terms to the Merchant in accordance with the Merchant’s terms and conditions and/or Kriya’s Disputes Policy, you can notify Kriya of a return to stop payments. Upon such notification, Kriya will pause the Payment Schedule by up to two (2) weeks to allow the Merchant to process your returned Goods and you will not incur late payment charges during this period. If the return is not completed within the two (2) week period (e.g. you later decide not to return the Goods or you are in fact not entitled to return the Goods), the Purchase Amount shall remain payable and the Payment Schedule shall resume. You will only be able to make this request once per order of Goods. '
      },
      {
        text: 'Until whichever is the earlier of: (a) such time that you cancel the Supply Contract in accordance with the terms of such Supply Contract or (b) such time that the Merchant has confirmed the return of the Goods and has notified Kriya of the amount of the refund for those Goods, you will remain responsible to us for the full payment of the Purchase Amount in accordance with the Payment Schedule and we will continue to process any payments in accordance with the Payment Schedule (following any two (2) weeks’ pause in accordance with Clause 10.1 above.'
      },
      {
        text: 'Once the Merchant (or if you are a Stripe Customer, Stripe) has notified Kriya of the amount of the refund due for the Goods returned that are subject to PayLater Payment Terms, Kriya will, on the same day, make the appropriate adjustment to the PayLater Payment Terms. If you have returned all the Goods to the Merchant and are entitled to a full refund under the Merchant’s terms and conditions, Kriya will end the Payment Schedule and issue a refund of any Payments made by you to Kriya under the Payment Method. If you have not returned all the Goods to the Merchant, or if the Merchant and/or Stripe (as applicable) has notified Kriya that a partial refund is due to you, Kriya will adjust the PayLater Payment Terms and the Payment Schedule appropriately (including to reduce or cancel any future Payments, if necessary). Please note, in the event of partial refunds, refund amounts are subtracted from the Payment Schedule in reverse order of maturity. If the refund is processed to your expired or cancelled card, you will need to obtain the returned funds by contacting your bank.'
      }
    ]
  },
  {
    title: 'WARRANTIES',
    content: [
      {
        text: 'By agreeing to these PayLater Terms of Business and each time you submit an PayLater Payment Terms Application, you (save as otherwise disclosed to and accepted by us in our sole discretion) represent and warrant, or will be treated as having represented and warranted, that:',
        content: [
          {
            text: 'you are a director or authorised representative of the Customer; or'
          },
          {
            text: 'if you are a natural person or general partnership (or a partner of such general partnership), you are acting in your capacity as such,'
          }
        ]
      },
      {
        text: 'and, in each case, agree to enter into the PayLater Agreement on the terms set out in this agreement and the PayLater Payment Terms Summary. You also confirm that failure to make payments in accordance with the Payment Schedule constitutes a Default of the Customer’s PayLater Payment Terms;',
        content: [
          {
            text: 'you have told us about every material fact which you know, or ought to know, that might influence us in deciding whether to offer PayLater Payment Terms or in determining whether to attach any conditions to your PayLater Payment Terms;'
          },
          {
            text: 'all information supplied by you via the PayLater Platform or otherwise is accurate and complete, and that you have authority to provide such information to us for analysis;'
          },
          {
            text: 'where required, you have declared all outstanding loans and borrowing arrangements with third parties to Kriya, including any loans or borrowing arrangements applied for but not yet approved, accepted or drawn with 3rd party lenders;'
          },
          {
            text: 'all the particulars in your Profile are correct and complete;'
          },
          {
            text: 'no Default has occurred or is continuing; '
          },
          {
            text: 'all the particulars given in any PayLater Payment Terms Application are correct and complete; and'
          },
          {
            text: 'any PayLater Payment Terms applied for by you and/or advanced to you shall be utilised by you solely for the purposes of the business carried on by you.'
          }
        ]
      },
      {
        text: 'The representations, warranties and undertakings contained in this PayLater Agreement will be repeated daily in respect of each set of PayLater Payment Terms from the date of the PayLater Payment Terms Application until the date the Purchase Amount are repaid in full together with all accrued interest and any other fees, expenses and charges under the terms of the PayLater Agreement.'
      }
    ]
  },
  {
    title: 'UNDERTAKINGS BY THE CUSTOMER',
    content: [
      {
        text: "Without Kriya's consent, you will not at any time after becoming a Customer use, divulge or communicate to any person (except to your professional representatives or advisers or as may be required by law or any legal or regulatory authority) any Confidential Information, and you will use your best endeavours to prevent the unauthorised publication or disclosure of any Confidential Information, and will only use Confidential Information for the purposes of proposing, considering or making transactions through the PayLater Platform."
      },
      {
        text: 'At all times, you undertake to:',
        content: [
          {
            text: 'comply with any ongoing requirements we may specify from time to time in respect of use of the PayLater Platform;'
          },
          {
            text: 'use the PayLater Platform, and the Licensed Materials in full compliance with the PayLater Agreement and the PayLater Guidance;'
          },
          {
            text: 'comply, in all material respects, with all applicable laws affecting you;'
          },
          {
            text: 'tell us immediately if you become aware of the occurrence of any Default; and'
          },
          {
            text: 'supply to us (promptly upon our request) or at our option make available for inspection by us any financial, know your customer or other information we may request relating to your business or affairs.'
          }
        ]
      }
    ]
  },
  {
    title: 'DEFAULT',
    content: [
      {
        text: 'Each of the following is a default (a **Default**):',
        content: [
          {
            text: 'one failed attempt is made by us to take a Payment on a Payment Date by the stipulated Payment Method;'
          },
          {
            text: 'you fail to pay any other sum due from you to us under this PayLater Agreement and such sum remains unpaid for a period of two (2) Business Days;'
          },
          {
            text: 'you are in breach of any other term of the PayLater Agreement and such breach remains unremedied for a period of more than ten (10) Business Days;'
          },
          {
            text: 'you are determined by us to have (or to have attempted to) hack into or otherwise interfere with the PayLater Platform, or we have reason to believe you have committed or attempted to commit any act of fraud on or through the PayLater Platform;'
          },
          {
            text: 'any information provided by or any representation or warranty given by you is false or misleading;'
          },
          {
            text: 'you fail to pay any sum due under any other financing facility made available by any person to you or otherwise breach the terms of any such facility and such failure to pay or breach is not remedied within five (5) Business Days;'
          },
          {
            text: 'a court order is made against you or any of your assets are affected by (i) any security being enforced or a receiver appointed or (ii) any court order or procedure to attach or take possession of them;'
          },
          {
            text: 'if you are a company or other legal person (other than a natural person or a general partnership),',
            content: [
              {
                text: 'you propose an arrangement with your creditors generally or are affected by a resolution or application for liquidation or administration or the start of insolvency proceedings or notice is given of intention to appoint an administrator, or an administrator is appointed; or'
              },
              {
                text: 'there is a change of control of you;'
              }
            ]
          },
          {
            text: 'if you are a natural person or a general partnership, you are or become unable to pay your debts within the meaning of section 123 of the Insolvency Act 1986 (ignoring the requirement in that section that any fact or matter ”is proved to the satisfaction of the court”); you suspend or threaten to suspend making payments with respect to all or any class of your debts; you propose or apply for any voluntary arrangement within Part VIII of the Insolvency Act 1986; or you propose any form of compromise with your creditors generally or any class of creditors;'
          },
          {
            text: 'you cease to carry on the business conducted by you when you made the PayLater Payment Terms Application;'
          },
          {
            text: 'you fail to set up, cancel or amend to our detriment any Payment Method;'
          },
          {
            text: 'we reasonably believe that continuing with your PayLater Agreement will result in sanction or prosecution by any government, regulatory or law enforcement agency;'
          },
          {
            text: 'we have reasonable grounds to suspect that a default is imminent; or'
          },
          {
            text: 'we, in our sole discretion, consider that there is a serious deterioration in your:',
            content: [
              {
                text: 'financial condition;'
              },
              {
                text: 'creditworthiness; '
              },
              {
                text: 'operating performance; '
              },
              {
                text: 'management and control;'
              },
              {
                text: 'general day to day administration and organisation; or '
              },
              {
                text: 'sales or debt ledger administration or credit control procedures. '
              }
            ]
          }
        ]
      },
      {
        text: 'If a Default occurs, we may then demand and you will immediately pay all the balance of the Purchase Amount outstanding and all other sums payable by you under the PayLater Agreement.'
      }
    ]
  },
  {
    title: 'CONSENTS, NOTICES AND DEMANDS',
    content: [
      {
        text: 'All consents, notices and demands must be in writing.'
      },
      {
        text: "Kriya may deliver a notice or demand to you by email or post to the contact details last known to Kriya or to the Customer's registered office."
      },
      {
        text: 'A notice or demand by Kriya will be effective at the time of sending an email or on the second business day after posting.'
      },
      {
        text: 'A notice from you to Kriya must be sent by email to CustomerSupport@kriya.co, or by post to Kriya’s registered address and must quote your PayLater Payment Terms reference (if there is one).',
        link: {
          text: 'CustomerSupport@kriya.co',
          link: 'mailto:customerSupport@kriya.co'
        }
      },
      {
        text: 'Notices to Kriya will be effective on receipt.'
      }
    ]
  },
  {
    title: 'INFORMATION, PRODUCT AND TRANSFERS',
    content: [
      {
        text: 'Kriya may give to anyone any information about you or your PayLater Agreement in connection with any proposed transfer of your PayLater Agreement.'
      },
      {
        text: 'Kriya may transfer or allow any person to take over any of their rights and duties under the PayLater Agreement.'
      },
      {
        text: 'You may not transfer the benefit of the PayLater Agreement.'
      },
      {
        text: 'We may at any time request that you provide any information relating to you or your use  of the PayLater Platform as we consider necessary or desirable for regulatory, compliance, risk or underwriting purposes. We may be under an obligation under applicable laws and regulations to transmit the Customer’s identity and credit information to regulatory authorities or registries, notably for purposes of credit information collection by prudential regulatory authorities of Kriya, subject to professional secrecy.'
      },
      {
        text: 'You authorise us at all times to undertake credit, identity and anti-money laundering checks and searches on you and, if relevant, your officers and beneficial owners when applicable. You accept that any information received by us will form part of our ongoing records.'
      },
      {
        text: 'We will use any Confidential Information concerning you for the purposes of operating the PayLater Platform, carrying out credit risk assessments, preventing fraud or money laundering and exercising our rights under the PayLater Agreement and/or any set of PayLater Payment Terms, and for those purposes we may at any time use, divulge or communicate to your employees, officers (if relevant), professional representatives or advisers or as may be required by law or any legal or regulatory authority or credit reference agencies any such Confidential Information. We may make Confidential Information available to any funder we use to finance the PayLater Payment Terms (including their employees, officers, representatives and finance parties) or any third party servicers who are necessary for the servicing or collection of the Purchase Amount and any fees (including any late payment fees) provided they are under obligations which are, at least, equivalent to those set out herein. We will use our reasonable endeavours to prevent the unauthorised publication or disclosure of any such Confidential Information.'
      },
      {
        text: '**How we use your information**: In the course of providing you with products and services we may collect, use, store and share personal and financial information about you, your business and other people connected with your business. We may collect this information from you directly or from third parties, such as credit reference agencies, fraud prevention agencies or public registers. We may also share Transaction Data with credit reference agencies and with any accountancy software that you agree to integrate with the PayLater Platform. We may also share this data with any funder we use to finance the PayLater Payment Terms (their employees, officers, representatives or finance parties) and any third party servicer engaged to service or collect the Purchase Amount and any fees (including any late payment fees). If we discover that you have provided us with false or inaccurate information and fraud is identified, your details will be passed to fraud prevention agencies to prevent fraud and money laundering. Further details on how the information held by fraud prevention agencies and credit reference agencies may be used is available here kriya.co/privacy-policy. You agree to us using your information in accordance with the Privacy Notice',
        link: {
          text: 'kriya.co/privacy-policy',
          link: 'https://www.kriya.co/privacy-policy'
        }
      }
    ]
  },
  {
    title: 'PAYLATER PLATFORM',
    content: [
      {
        text: 'We grant you a non-exclusive, non-transferable and revocable license to use, access and communicate through the PayLater Platform, and to use the Licensed Materials solely for the purposes contemplated by this PayLater Agreement (together "the **Licence**").'
      },
      {
        text: 'You will at all times recognise and respect our property and copyright in the PayLater Platform and/or the terms of the Licence and you may not copy or otherwise deal with the same without our written consent and you must safeguard and keep them confidential. We are entitled to compile and formulate Profiles, Kriya Analytics  and Transaction Data and such Profiles, Kriya Analytics and Transaction Data and the information contained in them will be our sole and exclusive property.'
      },
      {
        text: 'Your use of the PayLater Platform: You agree that we may immediately at any time without notice to you suspend your access to the PayLater Platform, thereby preventing you from making an PayLater Payment Terms Application (without releasing any of your obligations and liabilities to us).'
      },
      {
        text: 'You also agree that:',
        content: [
          {
            text: 'you will keep confidential all user identification numbers, passwords and other security processes and devices provided by us and ensure that only you have access to the PayLater Platform;'
          },
          {
            text: 'you are solely responsible for all PayLater Payment Terms Applications or other transactions or actions that take place using the PayLater Platform in your name that are carried out by any person, whether or not duly authorised, if you could or should have reasonably prevented them from accessing the PayLater Platform; and'
          },
          {
            text: "we have the absolute right not to act on your or any other person's instructions if we consider there is a risk that by taking or refraining to take action we may violate any law, regulation or other requirement of any governmental or other authority."
          }
        ]
      },
      {
        text: '**Restrictions**: We may immediately at any time without notice to you, impose or vary conditions relating to your use of the PayLater Platform, including setting a limit on the amount you request in an PayLater Payment Terms Application.'
      },
      {
        text: 'Where we take such action referred to above we shall, if permitted by all applicable laws, inform you once we have taken such action of:',
        content: [
          {
            text: 'the extent of our actions;'
          },
          {
            text: 'the reason(s) for our actions; and'
          },
          {
            text: 'any actions you must take to remedy the situation which caused us to impose or vary conditions relating to your use of the PayLater Platform.'
          }
        ]
      },
      {
        text: 'We make no representation or warranty of any kind (express or implied):',
        content: [
          {
            text: 'by providing the Licensed Materials and all information on and data contained within the PayLater Platform, which is provided on an "as is" and "as available" basis; or'
          },
          {
            text: 'that use of or access to the PayLater Platform will be uninterrupted, error free or free of computer viruses or computer bugs, or that defects will be corrected, or give any warranty otherwise as to their functionality, accuracy, or reliability.'
          }
        ]
      }
    ]
  },
  {
    title: 'PAYMENTS',
    content: [
      {
        text: 'You must immediately tell Kriya if your bank account details change.'
      },
      {
        text: 'We will only pay monies due to you to accounts held in your name with a UK bank or payment services firm authorised by the Financial Conduct Authority or other applicable  regulator. We reserve the right to refuse to execute a payment if:',
        content: [
          {
            text: 'we know, or have reasonable reason to suspect, that doing so would be in breach of any applicable law;'
          },
          {
            text: 'we have the right under the PayLater Agreement to refuse the payment; and/or'
          },
          {
            text: 'you are unable to provide us with documentation to verify that the external account is held in your name.'
          }
        ]
      },
      {
        text: 'For the purpose of determining both your liability to pay, and the amount you are liable to pay to us, a certificate in writing signed by any one of our directors (or authorised signatory under power of attorney) stating the amount due at any particular date will be conclusive evidence (save for manifest error), in any legal or insolvency proceedings. In certifying the amounts due we will be entitled to take into account all your liabilities (whether actual or contingent) and to make a reasonable estimate of any liability where its amount cannot immediately be ascertained.'
      },
      {
        text: 'A payment due on a non-Business Day, will be payable on the next Business Day.'
      },
      {
        text: 'All payments due from you under the PayLater Agreement must be made without set-off and without any deduction on account of any tax, duty or other charge, unless a deduction is required by law. If a deduction is required by law, the Customer will increase the payment so that Kriya receives the amount due before the deduction.'
      },
      {
        text: 'Kriya may apply any payments received to reduce any amounts owed by you to Kriya.'
      }
    ]
  },
  {
    title: 'REIMBURSEMENT AND SET-OFF',
    content: [
      {
        text: '**Reimbursement**: You will be responsible for paying in full for all losses, actions, proceedings, costs, claims, demands, awards, fines, orders, expenses and liabilities (including reasonable legal fees) incurred by us at any time (and any additional administrative time incurred by us charged at such rate as we may reasonably determine) directly or indirectly as a result of:',
        content: [
          {
            text: 'any breach of your obligations under this PayLater Agreement or preserving or enforcing our rights under this PayLater Agreement;'
          },
          {
            text: 'any failure to take a Payment through the required Payment Method;'
          },
          {
            text: 'exercising any rights to collect a Payment or dealing with disputes relating to a set of PayLater Payment Terms;'
          },
          {
            text: 'the occurrence of a Default;'
          },
          {
            text: 'your fraud or wilful misconduct, misuse of any technology or any intellectual property rights licensed to you'
          },
          {
            text: 'the transmission to us or our receipt or processing of incorrect, incomplete, inaccurate information or failure to receive a message or upload in each case however caused; or'
          },
          {
            text: 'accepting and/or acting upon any electronic message or other communication, information or instructions purporting to come from you even if that information or those instructions subsequently prove to have been incorrect or unauthorised by you.'
          }
        ]
      },
      {
        text: '**Kriya set-off**: We may (but are not obliged to) at any time without notice to you:',
        content: [
          {
            text: 'set-off against our liability to you any liability you owe to us, and for that purpose may make a reasonable estimate of any amounts contingently or prospectively due from you to us; and/or'
          },
          {
            text: "combine or consolidate all or any of your accounts with us, and if necessary to give effect to such right, we may convert currencies at our bank's prevailing spot rate of exchange."
          }
        ]
      }
    ]
  },
  {
    title: 'FEES AND COSTS',
    content: [
      {
        text: "You will from time to time pay Kriya's fees as detailed in the PayLater Agreement, including without limitation in your PayLater Payment Terms Summary."
      },
      {
        text: 'You will pay on demand all costs incurred by Kriya in connection with any default or potential default under the PayLater Agreement, including:',
        content: [
          {
            text: "preserving, defending or enforcing Kriya's rights"
          },
          {
            text: 'communicating with you, if you are, or are reasonably suspected of being, in Default or have notified Kriya of a potential Default'
          },
          {
            text: "collection agent’s and professional and other advisers' fees and costs."
          }
        ]
      },
      {
        text: 'All fees and charges are quoted exclusive of VAT, which will be added at the standard rate (where applicable).'
      }
    ]
  },
  {
    title: 'VARIATIONS',
    content: [
      {
        text: 'Kriya may vary this PayLater Agreement by giving you fourteen (14) days’ notice of a proposed variation by uploading the same to the PayLater Platform and/or by e-mail. If you do not agree the proposal, you may within fourteen (14) days of receiving notice immediately terminate your PayLater Agreement by written notice to us. If you do not object, or if you subsequently make an PayLater Payment Terms Application, you will be deemed to have accepted the proposed variation.'
      },
      {
        text: 'If you terminate your PayLater Agreement in accordance with this clause, you agree, on demand, to immediately pay the balance of any Purchase Amount then outstanding, together with all other sums (including any late payment fees) payable by you under the PayLater Agreement.'
      }
    ]
  },
  {
    title: 'LIMIT OF KRIYA LIABILITY',
    content: [
      {
        text: '**Our liability**: We (including our directors, agents and employees) shall not be liable to you in contract, tort (including negligence) or otherwise for (i) any consequential, secondary or indirect loss, injury or damage, and (ii) any business losses, such as loss of data, profits, anticipated savings, revenue, business, opportunity, goodwill, reputation or business interruption or for any losses which are not reasonably foreseeable by us arising, directly or indirectly from:',
        content: [
          {
            text: 'events outside our reasonable control that prevent us from doing something, including restrictions on convertibility or transferability of funds, requisitions, involuntary transfers, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, terrorism, war or acts of government;'
          },
          {
            text: 'use or loss of use, data or profits arising out of or in connection with the PayLater Agreement or the use or reproduction of Licensed Materials, including the use of or inability to use the PayLater Platform or the inability to obtain access to or to transact over the PayLater  Platform;'
          },
          {
            text: 'the conversion of monies owing to you from one currency to another at your request for no fee;'
          },
          {
            text: 'any error, fraud or misconduct attributable, directly or indirectly, to any act or omission on the part of any person other than us;'
          },
          {
            text: 'any unauthorised use of the PayLater Platform attributable, directly or indirectly, to any act or omission on the part of any person other than us;'
          },
          {
            text: 'any misuse by any person other than us of any Profile, Kriya Analytics or Transaction Data provided by us; or'
          },
          {
            text: 'any breach by a Customer of its obligations of confidentiality.'
          }
        ]
      },
      {
        text: '**Limit of our liability**: Notwithstanding any other provision of this Agreement, if we are found liable to you, our liability (whether arising in contract, restitution or tort) will be limited to an amount which is equal to the amount of any fees paid by the Merchant to Kriya in connection with any PayLater Payment Terms Application submitted by you as contemplated by the terms agreed between Kriya and the Merchant.'
      },
      {
        text: '**Not excluded**: Nothing in this clause shall limit or exclude our liability for fraudulent misrepresentation, for death or personal injury resulting from our negligence or the negligence of our agents or employees or for any other liability that cannot be limited or excluded by law.'
      }
    ]
  },
  {
    title: 'GENERAL',
    content: [
      {
        text: '**Illegality**: If any provision of the PayLater Agreement is held to be wholly or partially invalid, void or for any reason unenforceable, the provision or part shall be struck out and will not affect the validity and enforceability of the remaining provisions.'
      },
      {
        text: '**Whole agreement**: Your PayLater Agreement constitutes the whole agreement between you and us and supersedes all previous agreements and understandings relating to the subject matter.'
      },
      {
        text: '**Priority**: In the event of conflict between any term of your PayLater Agreement, the conflict will be resolved according to the following order of priority: (a) the PayLater Payment Terms Summary, (b) this PayLater Agreement and (c) the PayLater Guidance.'
      },
      {
        text: '**Non-waiver**: If Kriya does not enforce any of its rights under the PayLater Agreement, this does not constitute a waiver by Kriya of such rights and Kriya reserves its right in full to enforce all such rights at any time of its choosing.'
      },
      {
        text: '**Assignment**: Kriya may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights and obligations under the PayLater Agreement. You shall not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any of its rights and obligations under the PayLater Agreement.'
      },
      {
        text: '**Complaints**: If you wish to make a formal complaint about the services we provide to you under the PayLater Agreement, you may do so at any time by post, email or telephone at such addresses or on such telephone number as notified to you from time to time in the Complaint Procedure, which may be viewed here: kriya.co/feedback.',
        link: {
          text: 'kriya.co/feedback',
          link: 'https://www.kriya.co/feedback'
        }
      },
      {
        text: 'The PayLater Agreement is governed by English law and the English courts have exclusive jurisdiction.'
      },
      {
        text: 'For the benefit of Kriya, the Customer irrevocably submits to the jurisdiction of the English courts and irrevocably agrees that a ruling in those courts will be conclusive and binding on the Customer and may be enforced against the Customer in the courts of any other jurisdiction.'
      }
    ]
  },
  {
    title: 'DEFINITIONS',
    content: [
      {
        text: '**Defined terms**: In this PayLater Agreement (references to which will always incorporate any Schedule to such documents), “**Kriya**”, “**we**”, “**our**” and “**us**” are references to Kriya Finance Limited, 48-50 Scrutton Street, London EC2A 4XQ (No.7330525), references to “**you**” or “**your**” (unless the context otherwise requires) are references to the Customer (and in the event you are a general partnership, to you in your capacity as a partner and agent of a general partnership which is a Customer) and capitalised terms used herein shall have the following meanings:',
        contentWithoutMarker: true,
        content: [
          {
            text: '“**Business Days**” means a weekday other than a national holiday in England;'
          },
          {
            text: '“**Confidential Information**” means all information in whatever form relating to us or any Customer (including a previous Customer) which is disclosed or made available by us or over the PayLater Platform, including (a) the terms of the PayLater Agreement, (b) any information that would be regarded as confidential by a reasonable business person relating to the business, affairs, Customers, clients or suppliers of a Customer, and (c) any information or analysis derived from Confidential Information; but not including any information that (d) is or becomes generally available to the public other than as a result of a disclosure in breach of the PayLater Agreement, (e) was available to or lawfully in the possession of a Customer prior to any disclosure by us or over the PayLater Platform, or (f) is trivial, obvious or useless;'
          },
          {
            text: '“**Credit Agreement**” shall have the same meaning given to the term “credit agreement” under The Financial Services and Markets Act 2000 (Regulated Activities) Order 2001.'
          },
          {
            text: '“**Customer**” means any person, partnership, company or other legal entity which has agreed to be bound as a customer by the PayLater Agreement and who we have accepted as a “Customer”;'
          },
          {
            text: '“**Default**” means all the events described in clause 13.1 (Defaults);'
          },
          {
            text: '“**Disputes Policy**” means the policy, made available by Kriya and/or Stripe in respect of any Purchase Amount subject to a PayLater Agreement, which sets out the basis upon which Kriya will accept any complaints against a Merchant which has received a Purchase Amount funded via a PayLater Agreement; '
          },
          {
            text: '“**Goods**” means any goods or services the subject of a Supply Contract;'
          },
          {
            text: '“**Kriya Analytics**” means the results of our information, data and statistical analysis, with respect to Customers;'
          },
          {
            text: '“**Kriya Product**” means the products made available to sellers/ borrowers/ customers/ investors/ lenders generally by Kriya;'
          },
          {
            text: '“**Licensed Materials**” means Profiles, Transaction Data and other information provided by us or any of our authorised agents on the PayLater Platform;'
          },
          {
            text: '“**Merchant**” means an online Merchant or wholesaler that supplies goods and/or services and/or enables the supply of goods and/or services to you, in either case pursuant to a Supply Contract;'
          },
          {
            text: '“**Payment Date**” means each date of repayment of the Purchase Amount where applicable as set out in the Payment Schedule;'
          },
          {
            text: '“**Payment Method**” means direct debit, bank transfer and/or other payment mandate/method required by Kriya in connection with any set of PayLater Payment Terms;'
          },
          {
            text: '“**Payment(s)**” means the payment(s) of the amounts as specified in the Payment Schedule;'
          },
          {
            text: '“**PayLater Agreement**” means this agreement made when a person is accepted by Kriya as a Customer and which comprises the terms of this agreement, the Privacy Notice, the PayLater Guidance and the terms of any applicable PayLater Payment Terms, Summary;'
          },
          {
            text: '“**PayLater Guidance**” means all terms, guidance and instructions from time to time made available by us to you;'
          },
          {
            text: '“**PayLater Payment Terms**” means payment terms in respect of a Purchase Amount which may be, or is, provided to you in accordance with the terms of (and incorporated within) this PayLater Agreement, including any PayLater Payment Terms Summary;'
          },
          {
            text: '“**PayLater Payment Terms Application**” means the request for PayLater Payment Terms made by the Customer via the PayLater Platform;'
          },
          {
            text: '“**PayLater Payment Terms Summary**” means a summary of the key terms relating to each set of PayLater Payment Terms which will be sent to the Customer following a successful PayLater Payment Terms Application, including, but not limited to, the fixed sum credit to be provided to the Customer by Kriya and the related amounts payable by the Customer to Kriya and the Payment Schedule; '
          },
          {
            text: '“**PayLater Platform**” means the online payment and credit platform, related databases and supporting software operated by us and/or embedded within any of the  Merchant’s platforms, but excluding equipment, software and telecommunications configurations or link belonging to any third party;'
          },
          {
            text: '“**Payment Schedule**” means the schedule of payments to repay each set of PayLater Payment Terms as set out in the PayLater Payment Terms Summary;'
          },
          {
            text: '“**Privacy Notice**” means the “Privacy Policy” which is available at www.kriya.co/privacy-policy;',
            link: {
              text: 'www.kriya.co/privacy-policy',
              link: 'https://www.kriya.co/privacy-policy'
            }
          },
          {
            text: '“**Profile**” means information on each Customer collated by us, including without limitation:',
            withAlphaType: 'lower-alpha',
            content: [
              {
                text: "in relation to each Customer that is a natural person or a general partnership, the Customer's residential address, date of birth, country of birth, nationality, status, business, and financial and trading performance; and"
              },
              {
                text: 'in relation to each Customer that is a company or other legal entity (other than a natural person or a general partnership), the Customer’s corporate status, business, and financial and trading performance,  '
              }
            ],
            appendix:
              'and in either case where relevant, information relating to your business partners including information on their personal financial circumstances and business activities;'
          },
          {
            text: '“**Purchase Amount**” means the amount to be paid by the Customer in respect of the Goods;'
          },
          {
            text: '“**Supply Contract**” means a contract for the sale or supply of Goods by a Merchant to a Customer; '
          },
          {
            text: '“**Stripe**” means Stripe Payments Europe, Limited, Stripe Technology Europe Limited and/or Stripe Payments UK Limited; '
          },
          {
            text: '“**Stripe Customer**” means any person, partnership, company or other legal entity which has agreed to be bound as a customer by the PayLater Agreement and who we have accepted as a “Customer” and such PayLater Agreement has arisen during the course of a transaction between a Stripe Merchant using the Stripe PayLater Integration;'
          },
          {
            text: '“**Stripe Merchant**” means an online Merchant or wholesaler that supplies goods and/or services and/or enables the supply of goods and/or services to you, in either case pursuant to a Supply Contract and is a customer of Stripe and a user of the Stripe PayLater Integration;'
          },
          {
            text: '“**Stripe PayLater Integration**” means the integration of our PayLater Platform into the technology, solware, hardware, or middleware owned or licensed by Stripe and offered to customers of Stripe; and'
          },
          {
            text: '“**Transaction Data**” means data and other information compiled by us relating to transactions by Customers in relation to PayLater Payment Terms.'
          }
        ]
      },
      {
        text: 'Interpretation: In the PayLater Agreement:',
        content: [
          {
            text: 'references to the Customer or Kriya in the PayLater Agreement include their permitted respective successors and assigns.'
          },
          {
            text: 'headings are for ease of reference only and do not affect the meaning of any clause;'
          },
          {
            text: 'references to any document or part thereof will be construed as references to that document or part in force for the time being and as amended, varied or novated from time to time in accordance with its terms;'
          },
          {
            text: 'a discretion which we may exercise (an act or decision that we may do or decide), may be exercised in our sole and absolute discretion and without giving any reason;'
          },
          {
            text: 'a provision of law is a reference to that provision as amended or re-enacted;'
          },
          {
            text: '"including" or "includes" means including or includes without limitation;'
          },
          {
            text: '"material facts" means those facts, circumstances or opinions about the identity, business, financial condition or trading relationships of a Customer or other person which are reasonably likely to influence the decisions of a commercial person considering a financial transaction involving any one of them;'
          },
          {
            text: '"writing" or "written" includes fax, e-mail or other comparable means of writing;'
          },
          {
            text: 'any undertaking not to do any act or thing includes an undertaking not to permit or suffer the doing of that act or thing, and each undertaking will be given separately and not jointly and severally;'
          },
          {
            text: 'the singular includes the plural and vice versa;'
          },
          {
            text: "a time of day is a reference to London time, and a 'month' is a calendar month; and"
          },
          {
            text: 'a "person" is a reference to any individual person, corporation, firm, company, partnership, joint venture, unincorporated body of persons, or any state or any agency of a state, whether or not a separate legal entity.'
          }
        ]
      }
    ]
  }
]
