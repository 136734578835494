import React from 'react'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import LimitsBox from '../../components/LimitsBox'
import Logo from '../../components/Logo'
import { Content } from '../../assets/styles/ContentStyles'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'

const ChecksCompletionPage = (data: OnboardingJourney) => {
  return (
    <>
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <Box
        sx={{
          marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
        }}
      >
        <Content xsGapSize={theme.spacing(24)} smGapSize={theme.spacing(32)}>
          <Typography variant="h4" data-cy="message">
            Onboarding completed successfully. Your spending limit is now activated
          </Typography>
          <LimitsBox
            companyName={data.company.name}
            totalSpendingLimit={data.riskDecision.totalSpendingLimit}
            availableSpendingLimit={data.riskDecision.availableSpendingLimit}
            currency={data.riskDecision.currency}
            eligibilitySubject={'to use Kriya PayLater'}
          />
          <Content xsGapSize={theme.spacing(12)} smGapSize={theme.spacing(12)}>
            <Typography variant="h4" data-cy="sub-title-further-actions">
              What's next
            </Typography>
            <Typography variant="body2" data-cy="next-actions">
              {`You can now close this window and start shopping at ${data.merchant.name}.`}
            </Typography>
          </Content>
        </Content>
      </Box>
    </>
  )
}

export default ChecksCompletionPage
