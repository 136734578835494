import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import React from 'react'
import Typography from '@mui/material/Typography'
import theme from '../assets/theme'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { EligibilityAnswer } from '../types/enums/EligibilityAnswer'
import styled from '@emotion/styled'

interface Props<T extends FieldValues> {
  name: Path<T>
  title: string
  register: UseFormRegister<T>
}

const StyledTypography = styled(Typography)`
  ul > li {
    list-style-type: disc;
  }

  margin-bottom: ${theme.spacing(8)};
`

const EligibilityQuestion: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({ name, title, register }) => {
  return (
    <li>
      <StyledTypography variant="body2" dangerouslySetInnerHTML={{ __html: title }}></StyledTypography>

      <RadioGroup name={name} id={name}>
        <FormControlLabel
          value={1}
          control={
            <Radio
              {...register(name, {
                required: true,
                valueAsNumber: true
              })}
              id={`${name}_${EligibilityAnswer.Yes}`}
            />
          }
          label={EligibilityAnswer.Yes}
        />
        <FormControlLabel
          value={0}
          control={
            <Radio
              {...register(name, {
                required: true,
                valueAsNumber: true
              })}
              id={`${name}_${EligibilityAnswer.No}`}
            />
          }
          label={EligibilityAnswer.No}
        />
      </RadioGroup>
    </li>
  )
}

export default EligibilityQuestion
