import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'
import { AddressSearchResponseItem } from '../types/AddressSearchResponseItem'
import { apiAddressSearchClient } from '../api/apiAddressSearchClient'
import { AddressSearchResponse } from '../types/AddressSearchResponse'

export const fetchAddressSearchDataKey = 'fetch-address-search'

export const SearchByPostCode = async (
  postCode: string,
  houseNumber: string | undefined,
  limit: number | undefined
): Promise<AddressSearchResponseItem[]> => {
  if (!postCode) {
    return []
  }
  const houseNumberQueryParam = houseNumber ? `?houseNumber=${encodeURIComponent(houseNumber)}` : ''
  const response = await apiAddressSearchClient.get<AddressSearchResponse>(
    `/find-address/${encodeURIComponent(postCode)}${houseNumberQueryParam}`
  )

  if (response?.data?.IsSuccess && response.data.Value?.length > 0) {
    response.data.Value = response.data.Value.map((v) => {
      return { ...v, Label: getLabel([v.Line1, v.Line2, v.TownOrCity, v.Country]) }
    })

    if (limit) {
      return response.data.Value.slice(0, limit)
    }

    return response.data.Value
  }

  return []
}

export const useFetchAddressByPostCode = (
  postCode: string,
  houseNumber: string | undefined = undefined,
  limit: number | undefined = undefined,
  enabled: boolean
) => {
  const { data, isLoading } = useQuery<AddressSearchResponseItem[], Error>(
    [fetchAddressSearchDataKey, postCode, houseNumber],
    () => SearchByPostCode(postCode, houseNumber, limit),
    { retry, refetchOnWindowFocus: false, enabled: enabled }
  )

  return { data, isLoading }
}

const getLabel = (els: string[]): string => {
  let result = ''
  els.forEach((el) => (result = add(result, el)))
  return result
}

const add = (a: string, b: string): string => {
  return a + (a && b ? ', ' : '') + b
}
