import { useState } from 'react'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import TextInput from '../../components/TextInput'
import { emailPattern } from '../../utils/emailRegExp'
import { StyledButtonsContainer } from '../../components/layout/Container'
import ActionButton from '../../components/ActionButton'
import ReturnToMartketplaceDialog from '../../components/returnToMarketplace'
import captionBoxStyling from './captionBoxStyling'
import { useInitiateSoleTraderOnboardingJourney } from '../../custom-hooks/useInitiateSoleTraderOnboardingJourney'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import { maxLength150 } from '../../utils/validationConstants'
import SoleTraderDetailsForm from './SoleTraderDetailsForm'
import ManualEligibility from './ManualEligibility'

interface SoleTraderFormParams {
  onboardingJourney: OnboardingJourney
  setDisplayHeaderContent?: (shouldDisplay: boolean) => void
}

export type SoleTraderInformationForm = {
  soleTraderEmail?: string
  mobilePhoneNumber?: string
}

const SoleTraderForm = ({ onboardingJourney, setDisplayHeaderContent }: SoleTraderFormParams) => {
  onboardingJourney = { ...onboardingJourney, flowType: undefined }

  const form = useForm<SoleTraderInformationForm>({
    mode: 'onChange',
    defaultValues: {
      soleTraderEmail: onboardingJourney.director?.email || '',
      mobilePhoneNumber: onboardingJourney.director?.phoneNumber || ''
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  const [isInitiated, setIsInitiated] = useState(false)
  const [initialEmailSet] = useState(!!form.getValues().soleTraderEmail)
  const [initialPhoneNumberSet] = useState(!!form.getValues().mobilePhoneNumber)

  const onSubmit = (formValues: SoleTraderInformationForm) => {
    if (!isMutationLoading) {
      initiateSoleTraderFlow(formValues.soleTraderEmail!, {
        onSuccess: () => {
          setIsInitiated(true)
        }
      })
    }
  }

  const { mutate: initiateSoleTraderFlow, isLoading: isMutationLoading } = useInitiateSoleTraderOnboardingJourney({})

  if (initialEmailSet || initialPhoneNumberSet || isInitiated) {
    if (
      onboardingJourney.company?.kriyaCompanyIdentifier != null &&
      onboardingJourney.isManualEligibilityCheckPassed == null
    ) {
      return (
        <ManualEligibility onboardingJourney={onboardingJourney} setDisplayHeaderContent={setDisplayHeaderContent} />
      )
    }
    return <SoleTraderDetailsForm />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="soletrader-information-form">
      <Box sx={captionBoxStyling}>
        <TextInput
          label="Sole trader's email"
          name="soleTraderEmail"
          register={register}
          error={errors.soleTraderEmail}
          validation={{
            required: 'Please enter your email address',
            pattern: {
              value: emailPattern,
              message: 'Please enter a valid email address'
            },
            maxLength: maxLength150
          }}
        />

        <div style={{ marginTop: theme.spacing(16) }}>
          <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
            By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which
            may include soft checks at credit bureaus.
          </Typography>
        </div>

        <StyledButtonsContainer>
          <ActionButton
            id="continue-button"
            disabled={!form.formState.isValid || isMutationLoading}
            loading={isMutationLoading}
          >
            Continue
          </ActionButton>
          {onboardingJourney.abandonedUrl && (
            <ReturnToMartketplaceDialog
              marketplace={onboardingJourney.merchant.name}
              disabled={isMutationLoading}
              redirectUrl={onboardingJourney.abandonedUrl}
            ></ReturnToMartketplaceDialog>
          )}
        </StyledButtonsContainer>
      </Box>
    </form>
  )
}

export default SoleTraderForm
