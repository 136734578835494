import Box from '@mui/material/Box'
import { Content } from '../../assets/styles/ContentStyles'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../assets/theme'

const StyledPanel = styled(Box)`
  display: block;
  margin-top: ${theme.spacing(32)};
  padding: ${theme.spacing(16, 20)};
  ${theme.breakpoints.down('sm')} {
    padding: 0;
    min-width: 290px;
  }
`

type PageContainerContentProps = {
  children?: React.ReactNode
}

const PageContainerContent = (props: PageContainerContentProps) => {
  return (
    <StyledPanel>
      <Content>{props.children}</Content>
    </StyledPanel>
  )
}

export default PageContainerContent
