import Link from '@mui/material/Link'

type EligibilityRetryProps = {
  handleClick: () => void
}

const EligibilityRetry = ({ handleClick }: EligibilityRetryProps) => {
  return (
    <div style={{ maxWidth: '600px', flexGrow: 1 }}>
      Oops... It's taking longer than expected. Please give it another try by{' '}
      <Link component="button" variant="h1" onClick={handleClick}>
        clicking here
      </Link>
    </div>
  )
}

export default EligibilityRetry
