import CheckIsFailed from '../error/CheckIsFailed'
import CheckShoulBeReRequested from '../error/CheckShoulBeReRequested'
import DirectorKycContent from './DirectorKycContent'
import DirectorKycSession from './DirectorKycSession'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import { OnboardingJourneyStatus } from '../../types/enums/OnboardingJourneyStatus'
import DirectorStepContainer from '../directorStep/DirectorStepContainer'
import { FlowType } from '../../types/enums/FlowType'

const DirectorKycPage = (data: OnboardingJourney) => {
  if (data.status === OnboardingJourneyStatus.DirectorKycCheckShouldBeReRequested) {
    return <CheckShoulBeReRequested />
  }

  if (data.status === OnboardingJourneyStatus.DirectorKycCheckIsFailed) {
    return <CheckIsFailed returnUrl={data.abandonedUrl} merchantName={data.merchant.name} />
  }

  if (data.status === OnboardingJourneyStatus.DirectorKycCheckIsPending) {
    return (
      <DirectorKycSession
        onboardingJourneyId={data.id}
        abandonedUrl={data.abandonedUrl ?? data.ineligibleUrl}
        sessionUrl={data.kycSessionUrl!}
      />
    )
  }

  if (data.status === OnboardingJourneyStatus.DirectorKycCheckIsRequested && data.flowType === FlowType.SoleTrader) {
    return <DirectorStepContainer {...data} />
  }

  return <DirectorKycContent {...data} />
}

export default DirectorKycPage
