import { FieldValues, Path, UseFormGetValues } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import theme from '../assets/theme'

interface Props<T extends FieldValues> {
  name: Path<T>
  label: string
  getValues: UseFormGetValues<T>
  optional?: boolean
}

const ReadOnlyField: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  name,
  label,
  getValues,
  optional = false
}) => {
  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {optional ? (
          <>
            {label} <Typography variant="inputLabel">(optional)</Typography>{' '}
          </>
        ) : (
          label
        )}
      </FormLabel>

      <Box sx={{ paddingTop: theme.spacing(2) }} data-cy={name}>
        <Typography variant="caption">{getValues(name)}</Typography>
      </Box>
    </FormControl>
  )
}

export default ReadOnlyField
