import Box from '@mui/material/Box'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'

const StyledPanel = styled(Box)`
  display: block;
  text-align: left;
  margin-top: ${theme.spacing(80)};
  padding: ${theme.spacing(40, 48)};
  min-width: 368px;
  border-radius: 8px;
  border: 1px solid var(--grey-200, ${theme.palette.common.backgroundGrey});
  background: var(--white, ${theme.palette.common.white});
  box-shadow: 8px 8px var(--midnight-8, ${theme.palette.common.shadow});
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(32)};
    padding: ${theme.spacing(16)};
    min-width: 278px;
    box-shadow: 4px 4px var(--midnight-8, ${theme.palette.common.shadow});
  }
`

export const StyledErrorPageButtonContainer = styled(Box)`
  margin-top: ${theme.spacing(32)};
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(24)};
  }
`

type ErrorPageContainerProps = {
  title: string
  children?: React.ReactNode
}

const ErrorPageContainer = (props: ErrorPageContainerProps) => {
  return (
    <StyledPanel>
      <Box>
        <Typography variant="errorPageHeader" data-cy="title">
          {props.title}
        </Typography>
      </Box>
      <Box
        typography="errorPageContent"
        sx={{
          pt: theme.spacing(12)
        }}
      >
        {props.children}
      </Box>
    </StyledPanel>
  )
}

export default ErrorPageContainer
