interface IAPIURLMapping {
  [Key: string]: string
}

const apiUrlMapping: IAPIURLMapping = {
  'onboarding.kriya.co': 'https://api.kriya.co/onboardingjourney/',
  'onboarding.kriya.dev': 'https://api.kriya.dev/onboardingjourney/',
  localhost: 'https://api.kriya.dev/onboardingjourney/'
}

const apiCompanyInfoUrlMapping: IAPIURLMapping = {
  'onboarding.kriya.co': 'https://api.kriya.co/company-info/',
  'onboarding.kriya.dev': 'https://api.kriya.dev/company-info/',
  localhost: 'https://api.kriya.dev/company-info/'
}

const apiAddressSearchUrlMapping: IAPIURLMapping = {
  'onboarding.kriya.co': 'https://api.kriya.co/addresslookup/',
  'onboarding.kriya.dev': 'https://api.kriya.dev/addresslookup/',
  localhost: 'https://api.kriya.dev/addresslookup/'
}

const deploymentHost = (currentUrl: string): string => {
  return new URL(currentUrl).hostname
}

const apiBaseUrl = (currentUrl: string): string => {
  return apiUrlMapping[deploymentHost(currentUrl)]
}

const apiCompanyInfoBaseUrl = (currentUrl: string): string => {
  return apiCompanyInfoUrlMapping[deploymentHost(currentUrl)]
}

const apiAddressSearchBaseUrl = (currentUrl: string): string => {
  return apiAddressSearchUrlMapping[deploymentHost(currentUrl)]
}

const retry: number | boolean = deploymentHost(window.location.href) === 'localhost' ? false : 3

export { deploymentHost, retry, apiCompanyInfoBaseUrl, apiAddressSearchBaseUrl }
export default apiBaseUrl
