import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import theme from '../assets/theme'

export interface LogoProps {
  logoUri: string
}
const StyledBox = styled(Box)`
  display: flex;
  justify-content: left;
  padding-top: ${theme.spacing(16)};
  ${theme.breakpoints.down(400)} {
    padding-top: ${theme.spacing(8)};
  }
`
const StyledLogo = styled.img`
  max-width: 200px;
  max-height: 50px;
  ${theme.breakpoints.down('sm')} {
    max-width: 144px;
    max-height: 36px;
  }
  padding: ${theme.spacing(1)};
`
function Logo({ logoUri }: LogoProps) {
  return (
    <StyledBox data-cy="logo">
      <StyledLogo src={logoUri} alt="Merchant Logo"></StyledLogo>
    </StyledBox>
  )
}

export default Logo
