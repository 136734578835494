/* eslint-disable vars-on-top, no-var, prefer-template */
// Modified version of https://github.com/iansinnott/react-string-replace
import { ReactElement } from 'react'

const isRegExp = function (re: RegExp | string | undefined) {
  return re instanceof RegExp
}
export const escapeRegExp = function escapeRegExp(string: string) {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g,
    reHasRegExpChar = RegExp(reRegExpChar.source)

  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string
}
const isString = function (value: string | undefined) {
  return typeof value === 'string'
}

export const replaceString = (
  str: string,
  match: RegExp | string,
  fn: (match: string, index: number, startIndex: number) => ReactElement
): any => {
  let curCharStart = 0
  let curCharLen = 0

  if (str === '') {
    return str
  } else if (!str || !isString(str)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string')
  }

  let re = match

  if (!isRegExp(re)) {
    re = new RegExp('(' + escapeRegExp(re as string) + ')', 'gi')
  }

  const result = str.split(re).filter((r) => r != null)

  // Apply fn to all odd elements
  for (let i = 1, length = result.length; i < length; i += 2) {
    /** @see {@link https://github.com/iansinnott/react-string-replace/issues/74} */
    if (result[i] === undefined || result[i - 1] === undefined) {
      console.warn(
        'reactStringReplace: Encountered undefined value during string replacement. Your RegExp may not be working the way you expect.'
      )
      continue
    }

    curCharLen = result[i].length
    curCharStart += result[i - 1].length
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result[i] = fn(result[i], i, curCharStart)
    curCharStart += curCharLen
  }

  return result
}
