interface ISegment {
  page: <T extends object>(input: string, props?: T) => void
  virtualPageView: (path: string) => void
}

interface ISegmentWindow {
  analytics: ISegment
  hj?: (arg1: string, arg2: string | string[]) => void
}

const isSegmentWindow = (win: any): win is ISegmentWindow => {
  return (win as { analytics?: any }).analytics !== undefined
}

const mockSegmentWindow: ISegmentWindow = {
  analytics: {
    page: <T extends object>(input: string, props?: T): void => {
      console.log(`Tracking page view: ${JSON.stringify(input)} - ${JSON.stringify(props)}`)
    },
    virtualPageView: (path: string) => {
      console.log(`Tracking virtual page view: ${path}`)
    }
  },
  hj: undefined
}

class SegmentWrapperImpl implements ISegment {
  private segmentWindow?: ISegmentWindow

  constructor() {
    this.segmentWindow = isSegmentWindow(window) ? window : mockSegmentWindow
  }

  page = <T extends object>(input: string, props?: T): void => {
    this.segmentWindow?.analytics.page(input, props || {})
  }

  virtualPageView = <T extends object>(path: string, props?: T, isGoogleAnalytics?: boolean): void => {
    if (isGoogleAnalytics === true) {
      this.segmentWindow?.analytics.page(path, props)
    }

    if (typeof this.segmentWindow?.hj === 'function' && this.segmentWindow?.hj !== undefined) {
      this.segmentWindow?.hj('vpv', `/${path}/`)
      this.segmentWindow?.hj('trigger', path)
      this.segmentWindow?.hj('tagRecording', [`/${path}/`])
    }
  }
}

const SegmentWrapper = new SegmentWrapperImpl()
export default SegmentWrapper
