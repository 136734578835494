import React, { useEffect, useState } from 'react'
import EligibilityError from '../error/EligibilityError'
import EligibilityRetry from '../../components/EligibilityRetry'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import PageLoading from '../../components/loading/PageLoading'
import { useEligibilityInfo } from '../../custom-hooks/useEligibilityInfo'

const EligibilityCheckPage = (data: OnboardingJourney) => {
  const [pollingHeader, setPollingHeader] = useState<string | React.ReactNode>(
    'Checking your eligibility and spending limit...'
  )
  const [isTimerActivated, setIsTimerActivated] = useState<boolean>(false)

  const {
    mutate: checkEligibility,
    notifyTimeout,
    start
  } = useEligibilityInfo({
    onboardingJourneyId: data.id
  })

  useEffect(() => {
    if (!start && data !== undefined && !notifyTimeout) {
      checkEligibility(data.id)
    }
  }, [start, data, checkEligibility, notifyTimeout])

  const handleRetryClick = () => {
    checkEligibility(data.id)
    setPollingHeader('Checking your eligibility and spending limit...')
  }

  if (notifyTimeout && !start) {
    return (
      <EligibilityError onClick={handleRetryClick} merchantName={data.merchant.name} returnLink={data.abandonedUrl} />
    )
  }

  if (start) {
    setTimeout(() => {
      setPollingHeader(<EligibilityRetry handleClick={handleRetryClick} />)
      setIsTimerActivated(true)
    }, 15000)

    return (
      <PageLoading
        title={pollingHeader}
        body="Please don't close the window."
        returnLink={isTimerActivated ? data.abandonedUrl : undefined}
        returnLinkName={data.merchant.name}
      ></PageLoading>
    )
  }

  return <PageLoading title="Getting everything ready..." body="Please don't close the window." />
}

export default EligibilityCheckPage
