import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import ErrorPageContainer, { StyledErrorPageButtonContainer } from './ErrorPageContainer'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'

const SoleTraderDetailsInUse = () => {
  const navigate = useNavigate()
  const { merchantId, merchantCompanyIdentifier } = useParams()

  const handleReturnClick = () => {
    navigate(`/${merchantId!}/${merchantCompanyIdentifier!}`)
  }

  return (
    <ErrorPageContainer title="We’re sorry!">
      <Box data-cy="message" sx={{ gap: '20px', display: 'grid' }}>
        <Typography variant="body3">We're sorry, but this contact information is already in use.</Typography>
        <Typography variant="body3">Use a different email or mobile number to proceed.</Typography>
        <Typography variant="body3">
          For assistance, reach out to us at{' '}
          <Link href="mailto:customersupport@kriya.co">customersupport@kriya.co</Link>.
        </Typography>
      </Box>

      <StyledErrorPageButtonContainer>
        <Button variant="contained" onClick={handleReturnClick} id="return-to-onboarding-journey">
          Start again
        </Button>
      </StyledErrorPageButtonContainer>
    </ErrorPageContainer>
  )
}

export default SoleTraderDetailsInUse
