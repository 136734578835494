import { useMutation, useQueryClient } from 'react-query'

import { OnboardingJourneyStatus } from '../types/enums/OnboardingJourneyStatus'
import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'

export const updateOnboardingJourneyStatusDetails = async (
  onboardingJourneyId: string,
  phoneNumber?: string
): Promise<number> => {
  const response = await apiClient.put<number>(`${onboardingJourneyId}/status`, {
    status: OnboardingJourneyStatus.DirectorKycCheckIsPending,
    phoneNumber: phoneNumber ?? null
  })
  return response.status
}

export const useUpdateOnboardingJourneyStatus = ({
  onboardingJourneyId,
  onSuccess,
  onError
}: {
  onboardingJourneyId: string
  onSuccess?: () => void
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (request: { phoneNumber?: string }): Promise<number> => {
      const data = await updateOnboardingJourneyStatusDetails(onboardingJourneyId, request.phoneNumber)
      return data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
