import { CustomerDetailsStatuses, OnboardingJourneyStatus } from '../types/enums/OnboardingJourneyStatus'
import { InvalidCompanyStatus, RiskDecisionStatus } from '../types/enums/RiskDecisionStatus'
import { Navigate, useParams } from 'react-router-dom'

import DirectorKycPage from './directorKyc/DirectorKyc'
import NotFound from './error/NotFound'
import { OnboardingJourneyRedirection } from './error/OnboardingJourneyRedirection'
import PageLoading from '../components/loading/PageLoading'
import { useOnboardingJourneyInfo } from '../custom-hooks/useOnboardingJourneyQuery'
import CompanyInformationPage from './companyInformationStep/CompanyInformationStep'
import DirectorStepContainer from './directorStep/DirectorStepContainer'
import EligibilityCheckPage from './companyInformationStep/EligibilityCheckPage'
import { useEffect } from 'react'
import ChecksCompletionPage from './checksCompletion/ChecksCompletionPage'

const Redirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return null
}

const HomePage = () => {
  const { merchantId, merchantCompanyIdentifier } = useParams()

  const { data, dataLoading } = useOnboardingJourneyInfo(merchantId, merchantCompanyIdentifier)

  if (dataLoading) return <PageLoading title="Getting everything ready..." body="Please don't close the window." />

  if (data === undefined) {
    return <NotFound />
  }

  const riskStatus = data.riskDecision?.status?.toLowerCase()
  if (
    (data.status !== OnboardingJourneyStatus.New && InvalidCompanyStatus.includes(riskStatus as RiskDecisionStatus)) ||
    (riskStatus !== RiskDecisionStatus.Approved && !CustomerDetailsStatuses.includes(data.status))
  ) {
    const path = OnboardingJourneyRedirection(riskStatus as RiskDecisionStatus)
    if (path !== undefined) {
      return <Navigate to={path} replace state={data} />
    }
  }

  switch (data.status) {
    case OnboardingJourneyStatus.New:
      return <CompanyInformationPage {...data} />
    case OnboardingJourneyStatus.CompanyCheckIsRequested:
      return <EligibilityCheckPage {...data} />
    case OnboardingJourneyStatus.CompanyCheckHasCompletedWithEligible:
      return <DirectorStepContainer {...data} />
    case OnboardingJourneyStatus.OnboardingJourneyIsSuccessfullyCompleted:
      if (data.autoRedirectOnCompletion) {
        return <Redirect url={data.completedUrl} />
      }
      return <ChecksCompletionPage {...data} />
    default:
      return <DirectorKycPage {...data} />
  }
}
export default HomePage
