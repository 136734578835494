import { AxiosResponse } from 'axios'
import { SearchCompaniesRequest } from '../types/SearchCompaniesRequest'
import { apiCompanyInfoClient } from '../api/apiCompanyInfoClient'
import { defaultCountryCode } from '../consts'
import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'
import { SearchCompanyDetails } from '../types/SearchCompanyDetails'

export const fetchCompanyAutocompleteDataKey = 'fetch-company-autocomplete-data'

export const fetchCompanyAutocompleteDetails = async (
  companyInfo: string | undefined,
  minLength: number
): Promise<SearchCompanyDetails[]> => {
  if (!companyInfo || companyInfo.length <= minLength) return []

  const response = await apiCompanyInfoClient.post<SearchCompaniesRequest, AxiosResponse<SearchCompanyDetails[]>>(
    '/search',
    {
      fuzzyTerm: companyInfo,
      address: {
        country: defaultCountryCode
      }
    }
  )
  return response.data
}

export const useFetchCompanyAutocompleteData = (companyInfo: string | undefined, minLength: number) => {
  const { data, isLoading } = useQuery<SearchCompanyDetails[], Error>(
    [fetchCompanyAutocompleteDataKey, companyInfo],
    () => fetchCompanyAutocompleteDetails(companyInfo, minLength),
    { retry, refetchOnWindowFocus: false }
  )

  return { data, isLoading }
}
