import { SearchCompaniesResponse } from '../types/SearchCompaniesResponse'
import { dnbCountryRegistrationNumberType, dunsNumberType } from '../consts'
import { AuxiliaryIdentifier } from '../types/AuxiliaryIdentifier'

const dnbCountryRegistrationNumberTypeLower = dnbCountryRegistrationNumberType.toLowerCase()

export const getGbCompanyRegistrationNumber = (company: SearchCompaniesResponse): string => {
  return (
    company.auxiliaryIdentifiers?.find(
      (auxiliaryIdentifier) => auxiliaryIdentifier.type.toLowerCase() === dnbCountryRegistrationNumberType.toLowerCase()
    )?.identifier || ''
  )
}

export const hasGbCompanyRegistrationNumber = (company: SearchCompaniesResponse): boolean => {
  return (
    company.auxiliaryIdentifiers?.find(
      (auxiliaryIdentifier) => auxiliaryIdentifier.type.toLowerCase() === dnbCountryRegistrationNumberType.toLowerCase()
    )?.identifier !== undefined
  )
}

export const getSuitableCompanyIdentifier = (auxiliaryIdentifiers?: AuxiliaryIdentifier[]): string => {
  if (!auxiliaryIdentifiers || auxiliaryIdentifiers.length === 0) return ''

  const crn = auxiliaryIdentifiers.find(
    (auxiliaryIdentifier) => auxiliaryIdentifier.type.toLowerCase() === dnbCountryRegistrationNumberTypeLower
  )
  if (crn) return crn.identifier

  const dnbNumber = auxiliaryIdentifiers.find((auxiliaryIdentifier) => auxiliaryIdentifier.type === dunsNumberType)
  if (dnbNumber) return dnbNumber.identifier

  return auxiliaryIdentifiers[0].identifier
}
