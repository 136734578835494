import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { TermsAndConditions } from './TermsAndConditions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import styled from '@emotion/styled'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import { escapeRegExp, replaceString } from '../../utils/stringReplace'

export type SignedTermsCheckProps = {
  field: { value?: boolean }
  setValue: (value: boolean) => void
}

type TermsAndConditionsItem = {
  title?: string
  text?: string
  content?: TermsAndConditionsItem[]
  appendix?: string
  contentWithoutMarker?: boolean
  withAlphaType?: string
  link?: TermsAndConditionsItemLink
}
type TermsAndConditionsItemLink = {
  text: string
  link: string
}

const StyledList = styled(List)`
  counter-reset: item;
  text-align: justify;
  font-family: ${theme.typography.fontFamily};
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  list-style: decimal;
`

const StyledListItem = styled(ListItem)`
  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }

  .without-style {
    > :before {
      content: '';
    }
  }

  .with-alpha-type {
    > :before {
      content: '(' counter(item, lower-alpha) ') ';
    }
  }

  display: block;
  padding-left: 0;
`

const StyledListDescription = styled.span`
  font-weight: 400;
`

const GetFormattedValue = (value: string, link?: TermsAndConditionsItemLink): ReactElement => {
  const regExp = new RegExp(`\\*\\*(.+?)\\*\\*${link ? `|(${escapeRegExp(link.text)})` : ''}`)
  const initialValue = replaceString(value, regExp, (match) => {
    if (match === link?.text) {
      return <Link href={link.link}>{link.text}</Link>
    }
    return <strong>{match}</strong>
  })

  return initialValue as ReactElement
}

const ParseContent = (item: TermsAndConditionsItem) => (
  <StyledListItem key={`${item.title || ''}##${item.text || ''}##item`}>
    {item.title && (
      <Fragment key={`${item.title || ''}##${item.text || ''}##title`}>
        <span>{item.title}</span>
        <br />
      </Fragment>
    )}
    {item.text && (
      <StyledListDescription key={`${item.title || ''}##${item.text || ''}##description`}>
        {GetFormattedValue(item.text, item.link)}
      </StyledListDescription>
    )}
    {item.content && (
      <StyledList
        className={[item.contentWithoutMarker ? 'without-style' : '', item.withAlphaType ? 'with-alpha-type' : ''].join(
          ' '
        )}
        style={{ fontWeight: 400 }}
        key={`${item.title || ''}##${item.text || ''}##list`}
      >
        {item.content.map((tac: TermsAndConditionsItem) => ParseContent(tac))}
      </StyledList>
    )}
    {item.appendix && (
      <StyledListDescription
        style={{ display: 'block', marginLeft: theme.spacing(8) }}
        key={`${item.title || ''}##${item.text || ''}##appendix`}
      >
        {item.appendix}
      </StyledListDescription>
    )}
  </StyledListItem>
)

const TermsDialogContent = <StyledList>{TermsAndConditions.map((item) => ParseContent(item))}</StyledList>

const SignedTermsCheck = ({ field, setValue }: SignedTermsCheckProps) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    setValue(true)
    handleClose()
  }

  const label = (
    <Typography variant="body2semibold">
      I confirm that I have read, understood, and agree to the
      <Button
        variant="component"
        sx={{
          height: {
            xs: '14px',
            sm: '16px'
          },
          p: theme.spacing(0, 0, 2, 1),
          fontSize: {
            xs: '14px',
            sm: '16px'
          },
          fontWeight: 600
        }}
        onClick={handleClickOpen}
        data-cy="terms-and-conditions-btn"
      >
        Terms and Conditions
      </Button>
    </Typography>
  )
  const descriptionElementRef = React.useRef<HTMLElement>(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <>
      <FormControlLabel
        sx={{ alignItems: 'flex-start' }}
        control={<Checkbox id="signed-terms-input" sx={{ paddingTop: 0 }} checked={field.value === true} {...field} />}
        label={label}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth
        maxWidth="lg"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          color: theme.palette.common.black
        }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ color: theme.palette.common.black, margin: theme.spacing(16, 24, 12), padding: 0 }}
        >
          Terms and Conditions
        </DialogTitle>
        <DialogContent sx={{ margin: theme.spacing(0, 24), padding: 0 }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ color: theme.palette.common.black }}
          >
            {TermsDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: theme.spacing(16) }}>
          <Button onClick={handleClose} data-cy="terms-and-conditions-cancel-btn">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} data-cy="terms-and-conditions-agree-btn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignedTermsCheck
