import { useMutation, useQueryClient } from 'react-query'
import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'
import { CheckEligibilityAnswersRequest } from '../types/CheckEligibilityAnswersRequest'

export const checkEligibilityAnswers = async (eligibilityAnswers: CheckEligibilityAnswersRequest): Promise<number> => {
  const response = await apiClient.post('manualeligibilityquestions/check', eligibilityAnswers)
  return response.status
}

export const useCheckEligibilityAnswers = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (eligibilityAnswers: CheckEligibilityAnswersRequest): Promise<number> => {
      return await checkEligibilityAnswers(eligibilityAnswers)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
