import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { CompanyType } from '../../types/enums/CompanyType'
import theme from '../../assets/theme'

export type CompanyStructureProps = {
  companyType?: string
  onCompanyTypeChange: (value: string) => void
}

const CompanyStructure = ({ companyType, onCompanyTypeChange }: CompanyStructureProps) => {
  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onCompanyTypeChange(value)
  }
  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(10) }}>
        <Typography data-cy="header" variant="caption">
          What is your business structure?
        </Typography>
      </Box>
      <Box>
        <RadioGroup
          id="company-structure-radio-buttons-group"
          aria-labelledby="company-structure-radio-buttons-group"
          name="company-structure-radio-buttons-group"
          value={companyType}
          onChange={onChange}
        >
          <FormControlLabel
            value={CompanyType.LimitedCompany}
            control={<Radio id="limited-company-radio" />}
            label="Limited company/Government entity"
          />
          <FormControlLabel
            value={CompanyType.SoleTrader}
            control={<Radio id="sole-trader-radio" />}
            label="Sole trader"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default CompanyStructure
