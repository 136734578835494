import { apiClient } from '../api/apiClient'
import { useQuery, useQueryClient } from 'react-query'
import { KycStatusCheck } from '../types/KycStatusCheck'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'

export const checkKycStatus = 'fetch-kyc-status'

export const fetchKycStatusCheck = async (onboardingJourneyId: string): Promise<KycStatusCheck> => {
  const response = await apiClient.get<KycStatusCheck>(`/kycStatus/${onboardingJourneyId}`)
  return response.data
}

export const useCheckKycStatus = ({ onboardingJourneyId }: { onboardingJourneyId: string }) => {
  const queryClient = useQueryClient()
  const [start, setStart] = useState(false)
  const navigate = useNavigate()

  const onErrorHandler = () => {
    setStart(false)
    navigate('/error')
  }

  const onSuccessHandler = async () => {
    await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
  }

  useQuery<KycStatusCheck, Error>([checkKycStatus], () => fetchKycStatusCheck(onboardingJourneyId), {
    enabled: start,
    refetchInterval: start ? 3000 : false,
    refetchOnWindowFocus: false,
    onSuccess: async (data: KycStatusCheck) => {
      if (data.isKycSessionCompleted) {
        await onSuccessHandler()
      }
    },
    onError: () => {
      onErrorHandler()
    }
  })

  return { startPolling: setStart, isPolling: start }
}
