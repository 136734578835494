import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'
import theme from '../../assets/theme'

export const StyledButtonsContainer = styled(Box)`
  display: flex;
  justify-content: left;
  margin-top: ${theme.spacing(12)};
  ${theme.breakpoints.down('sm')} {
    justify-content: center;
    flex-direction: column;
  }
`
