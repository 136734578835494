import React from 'react'
import Paper from '@mui/material/Paper'
import theme, { eligibleSuccessColor } from '../assets/theme'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { formatCurrency } from '../utils/formatCurrency'

export type LimitsBoxProps = {
  companyName: string
  totalSpendingLimit: number
  availableSpendingLimit: number
  currency: string
  eligibilitySubject: string
}

const LimitsBox = ({
  companyName,
  totalSpendingLimit,
  availableSpendingLimit,
  currency,
  eligibilitySubject
}: LimitsBoxProps) => {
  return (
    <Paper
      sx={{
        margin: '0 auto',
        padding: theme.spacing(18),
        borderRadius: '6px',
        border: '2px',
        borderStyle: 'solid',
        borderColor: eligibleSuccessColor,
        color: eligibleSuccessColor,
        background: 'rgba(25, 135, 84, 0.08)',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(12)
      }}
      data-cy={'company-information-box'}
    >
      <Typography variant={'body2'} color={eligibleSuccessColor} data-cy="eligible-title">
        Your organisation, {companyName}, is <b>eligible</b> {eligibilitySubject}.
      </Typography>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: theme.spacing(16) }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <Typography variant="body2" data-cy="title-spending-limit" color={eligibleSuccessColor}>
            Total spending limit
          </Typography>

          <Typography variant="subtitle2" data-cy="spending-limit-value" fontWeight={600}>
            {formatCurrency(totalSpendingLimit, currency)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <Typography
            variant="body2"
            data-cy="title-available-limit"
            color={eligibleSuccessColor}
            sx={{
              textAlign: { xs: 'left', sm: 'right' }
            }}
          >
            Available spending limit
          </Typography>
          <Typography
            variant="subtitle2"
            data-cy="available-limit-value"
            fontWeight={600}
            sx={{
              textAlign: 'left',
              marginLeft: { xs: 0, sm: theme.spacing(7) }
            }}
          >
            {formatCurrency(availableSpendingLimit, currency)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default LimitsBox
