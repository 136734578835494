import Box from '@mui/material/Box'
import React from 'react'
import styled from '@emotion/styled'

const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 576px;
  margin: 0 auto;
  padding: 0px 16px;
  box-sizing: border-box;
  padding-bottom: 48px;
`

const Layout = ({ children }: { children: React.ReactNode }) => {
  return <CenteredBox>{children}</CenteredBox>
}

export default Layout
