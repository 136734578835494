import { useMutation, useQueryClient } from 'react-query'

import { apiClient } from '../api/apiClient'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import routes from '../routes'
import { useNavigate } from 'react-router-dom'

export const initiateKyc = async (onboardingJourneyId: string): Promise<number> => {
  const response = await apiClient.post<number>(`initiateKyc/${onboardingJourneyId}`)
  return response.status
}

export const useInitiateKyc = ({
  onboardingJourneyId,
  onSuccess,
  onError
}: {
  onboardingJourneyId: string
  onSuccess?: () => void
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    async (): Promise<number> => {
      return await initiateKyc(onboardingJourneyId)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate(routes.error)
        }
      }
    }
  )

  return { mutate, isLoading }
}
