import Box from '@mui/material/Box'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../assets/theme'

const ErrorMessage = styled.div`
  display: inline-block;
  padding-left: ${theme.spacing(2)};
`

type ErrorProps = {
  children?: string
  id: string
  showIcon?: boolean
}

const ErrorText: React.FC<ErrorProps> = ({ children, id, showIcon = true }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        typography: 'errorText',
        paddingTop: theme.spacing(3),
        alignItems: 'center'
      }}
    >
      {showIcon && <ErrorOutlinedIcon fontSize="small" />}
      <ErrorMessage role="alert" id={`${id}-error`} data-cy={`${id}.error`}>
        {children}
      </ErrorMessage>
    </Box>
  )
}

export default ErrorText
